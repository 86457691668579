import React from "react";
import TopBannerComponent from "./top-banner/top-banner";
import HowItWorks from "./how-it-works/how-it-works";
import ReferFriend from "./refer-friend/refer-friend";
import OurProductsComponent from "./our-products/our-products";
import FooterComponent from "./footer/footer";
import "./home.scss";
import LoyaltyContextProvider from "../../../provider/loyalty-provider";

export default function HomeScreen(props) {
  return (
    <div className="home-screen">
      <LoyaltyContextProvider>
        <TopBannerComponent></TopBannerComponent>
      </LoyaltyContextProvider>
      <HowItWorks></HowItWorks>
      <OurProductsComponent></OurProductsComponent>
      <ReferFriend></ReferFriend>
      <FooterComponent></FooterComponent>
    </div>
  );
}
