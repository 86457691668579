import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import DashboardAPI from '../screens/dashboard-screen/loyalty-dashboard/loyalty-dashboard-screen/loyalty-dashboard-api';

export const LoyaltyContext = createContext();

const LoyaltyContextProvider = (props) => {
    const [loadingIndicator, setLoadingIndicator] = useState(true);
    const [nextService, setNextService] = useState(null);
    const [vehicles, setVehicles] = useState([]);

    useEffect(() => {
        async function GetDashboardAPI() {
            const response = await DashboardAPI();
            if (response.status_code == 200) {
                setVehicles(response.result.vehicles);

                let next_service = _calculateNextService(response.result.vehicles);
                setNextService(next_service);

                setTimeout(() => setLoadingIndicator(false), 1000);
            } else {
                console.log(response)

                setTimeout(() => setLoadingIndicator(false), 1000);
            }
        }
        if (Cookies.get('access_token') != null) {
            GetDashboardAPI();
        }
    }, []);

    return (
        <LoyaltyContext.Provider value={{ loadingIndicator, vehicles, nextService }}>
            {props.children}
        </LoyaltyContext.Provider>
    )
}

function _calculateNextService(vehicles) {
    let today = new Date();
    let services = [];
    let next_service = null;

    if (Array.isArray(vehicles) && vehicles.length) {
        services = vehicles.filter((vehicle) => vehicle.next_service_date != "0001-01-01T00:00:00Z");
    }

    if (Array.isArray(services) && services.length) {
        next_service = services.reduce((a, b) => {
            if (a.next_service_date - today < b.next_service_date - today) {
                return a;
            } else {
                return b;
            }
        });
    }

    return next_service;
}

export default LoyaltyContextProvider;