import React, { useContext, useState } from "react";
import StepBarComponent from "../../step-bar-component/step-bar-component";
import StepTwoComponent from "../../step-2/step-2";
import "./step-1-form.scss";
import { AddServiceContext } from "../../../../../../provider/add-service-provider";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  minLengthMessage,
  requiredMessage,
} from "../../../../../../helpers/form-validation-message";
import { PopupContext } from "../../../../../../provider/popup-provider";
import { CheckInitialValidation } from "../../add-service-api";
import SpinnerComponent from "../../../../../../templates/global-component/spinner-component/spinner-component";
import { errorMessageHandler } from "../../../../../../helpers/error-handler";

export default function StepOneFormComponent(props) {
  const { register, handleSubmit, errors, watch } = useForm();
  const { service, setService } = useContext(AddServiceContext);
  const { popup, setPopup, closePopup } = useContext(PopupContext);

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    setIsLoading(true);
    if (!isLoading) {
      let serviceInfo = Object.assign({}, service);

      const response = await CheckInitialValidation(
        data.workshop_id,
        data.current_mileage,
        serviceInfo.cycle_guid
      );

      if (response.status_code == 200) {
        if (
          service.workshop_id.floating.length > 0 &&
          data.workshop_id !== service.workshop_id.floating
        ) {
          const popupVal = Object.assign({}, popup);
          popupVal.open = true;
          popupVal.img = "danger-icon";
          popupVal.content = [
            "We noticed you’ve selected a different workshop ID. Please note that you’ll be starting a new service cycle.",
          ];

          popupVal.buttons = [
            {
              type: "default",
              text: "Go Back",
              tap: () => {
                closePopup();
              },
            },
            {
              type: "primary",
              text: "Next",
              tap: () => {
                serviceInfo.current_mileage = data.current_mileage;
                serviceInfo.workshop_id.floating = data.workshop_id;
                serviceInfo.current_service.floating = 0;
                serviceInfo.oil.float = "";
                serviceInfo.screen = <StepTwoComponent></StepTwoComponent>;
                setService(serviceInfo);
              },
            },
          ];
          setPopup(popupVal);
          setIsLoading(false);
        } else {
          serviceInfo.current_mileage = data.current_mileage;
          serviceInfo.workshop_id.floating = data.workshop_id;
          serviceInfo.current_service.floating = service.current_service.ori;
          serviceInfo.oil.float = service.oil.ori;
          serviceInfo.screen = <StepTwoComponent></StepTwoComponent>;
          setService(serviceInfo);
        }
      } else { 
        if (response.status_code == 'internet') {
          let popupVal = Object.assign({}, popup);
          popupVal.open = true;
          popupVal.content = [
            "Oh no! Seems like you're not connected to the internet. Make sure your WiFi or mobile data is turned on.",
          ];
          popupVal.img = "";
          popupVal.buttons = [
            {
              type: "primary",
              text: "TRY AGAIN",
              tap: () => {
                closePopup();
              },
            },
          ];

          setPopup(popupVal);
          setIsLoading(false);
        } else { 
            const popupVal = Object.assign({}, popup);
            popupVal.open = true;
            popupVal.img = "danger-icon";
            let msg = errorMessageHandler(response);
            popupVal.content = [msg];
            popupVal.buttons = [
              {
                type: "primary",
                text: "Close",
                tap: () => {
                  closePopup();
                },
              },
            ];
            setPopup(popupVal);
            setIsLoading(false);
          }
      }
    }
  };

  return (
    <section className="step-one-form-component pb-5">
      <div className="container mb-1">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-4 px-4">
              <h2 className="text-center mb-4">STEP 1</h2>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-4 px-4">
              <StepBarComponent active="1"></StepBarComponent>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-4 px-4">
              <p className="text-center page-description">
                Vehicle &amp; workshop details
              </p>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-md-4 px-4 mt-1">
              <p className="vehicle-info mb-2">{service.car_name}</p>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-md-4 px-4">
              <div className="input-control">
                <input
                  type="text"
                  ref={register({
                    required: {
                      value: true,
                      message: requiredMessage("Workshop ID"),
                    },
                    minLength: {
                      value: 4,
                      message: minLengthMessage("Workshop ID", 4),
                    },
                  })}
                  name="workshop_id"
                  placeholder="Workshop ID"
                  className="tom-input"
                  autoComplete="off"
                  maxLength="10"
                  defaultValue={
                    service.workshop_id.floating != ""
                      ? service.workshop_id.floating
                      : ""
                  }
                />
                <small className="error">
                  <ErrorMessage errors={errors} name="workshop_id" />
                </small>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-4 px-4 mb-5">
              <div className="input-control">
                <input
                  type="number"
                  ref={register({
                    required: {
                      value: true,
                      message: requiredMessage("Current mileage"),
                    },
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "*Please enter a valid mileage",
                    },
                  })}
                  name="current_mileage"
                  inputMode="decimal"
                  className="tom-input"
                  placeholder="Current mileage (km)"
                  onKeyPress={(e) => {
                    return e.target.value.toString().length < 7
                      ? ""
                      : e.preventDefault();
                  }}
                  defaultValue={
                    service.current_mileage != null
                      ? service.current_mileage
                      : ""
                  }
                />
                <small className="error">
                  <ErrorMessage errors={errors} name="current_mileage" />
                </small>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-4 px-4 text-center text-md-right">
              <button
                className="tom-button primary-button"
                disabled={isLoading}
              >
                Next
                {isLoading ? (
                  <SpinnerComponent
                    fontColor={"text-white"}
                    dimensions={{ mobile: "14px", desktop: "1rem" }}
                  ></SpinnerComponent>
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}
