import Axios from "axios";
import Cookie from 'js-cookie';
import querystring from "querystring";
import { apiURL, excludeUrls } from "../config/config";

// Initialize interceptor
export const interceptorInit = () => {
    // Response interceptor for API calls
    Axios.interceptors.response.use((response) => {
        if (response.data.status_code === 401) {
            return errorHandler(response);
        }
        return response
    });
};

async function errorHandler(error) {
    const excludedList = mapExcludedUrl();
    const originalRequest = error.config;

    // Retry if url is not in the excluded list
    if (!excludedList.includes(originalRequest.url)) {
        if (error.data.status_code === 401
            && !originalRequest._retry
            && Cookie.get('access_token') != undefined) {
            originalRequest._retry = true;
            const access_token = await refreshAccessToken();
            Axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
            return Axios(originalRequest);
        }
        return Promise.reject(error);
    }
}

// Rebuild excluded url list
function mapExcludedUrl() {
    let urls = excludeUrls.map((url) => {
        return apiURL + url;
    })

    return urls;
}

async function refreshAccessToken() {
    try {
        let response = await fetch(`${apiURL}/api/user/auth/token`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Accept': 'application/json' },
            body: querystring.stringify({ refresh_token: Cookie.get('refresh_token') })
        });

        let data = await response.json();
        if (data.status_code === 200) {
            let tokens = data.result;

            // set cookie
            Cookie.set('access_token', tokens.access_token, { expires: 180 });
            Cookie.set('refresh_token', tokens.refresh_token, { expires: 180 });
            window.location.reload();

            return tokens.access_token;
        }
        else {
            // TODO:Redirect to login
            window.location.href = "/logout";
        }
    } catch (err) {
        console.log('Error: ', err);
    }
}
