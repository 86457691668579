// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import Cookie from "js-cookie";
import SeoComponent from './share-component/seo-component';

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  nav_active: nav_active,
  ...rest
}) => {

  const isLoggedIn = Cookie.get("access_token") != null ? true : false;

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? (
          <Layout {...props} nav_active={nav_active}>
            <SeoComponent></SeoComponent>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  )
}

export default PrivateRoute