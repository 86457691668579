import React, { useState, useEffect, Fragment, useContext } from "react";
import "./navbar.scss";
import SidebarComponent from "../sidebar/sidebar";
import { Link, useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { NavActiveContext } from "../../../provider/navbar-provider";
import { InboxContext } from "../../../provider/inbox-provider";
import { UserContext } from "../../../provider/user-provider";
import { PopupContext } from "../../../provider/popup-provider";

export default function NavbarComponent(props) {
  const { popup, setPopup } = useContext(PopupContext);
  const { nav_active } = useContext(NavActiveContext);
  const { inboxIndicator } = useContext(InboxContext);
  const { logout } = useContext(UserContext);
  const [sidebar_status, setSidebarStatus] = useState("");
  const history = useHistory();

  useEffect(() => {
    document.body.style.overflowY = "auto";
    window.scroll({ top: 0 });
  }, []);

  const toggleMenu = () => {
    let elem = document.querySelector(".menu-toggler");
    switch (elem.classList.contains("active")) {
      case true:
        elem.classList.remove("active");
        setSidebarStatus("");
        document.body.style.overflowY = "auto";
        break;
      default:
        elem.classList.add("active");
        setSidebarStatus("active");
        document.body.style.overflowY = "hidden";
        break;
    }
  };

  const onLogout = () => {
    let content = ["Are you sure you want to logout?"];
    let imageIcon = "danger-icon";
    let buttons = [
      {
        type: "default",
        text: "No",
        tap: () => {
          let popupVal = Object.assign({}, popup);
          popupVal.open = false;
          setPopup(popupVal);
        },
      },
      {
        type: "primary",
        text: "YES",
        tap: () => {
          logout();
        },
      },
    ];

    let popupVal = Object.assign({}, popup);
    popupVal.open = true;
    popupVal.img = imageIcon;
    popupVal.content = content;
    popupVal.buttons = buttons;
    setPopup(popupVal);
  };

  return (
    <Fragment>
      <nav className="navbar-component">
        <div className="nav-desktop">
          <div className="container d-flex justify-content-between">
            <Link to="/">
              <img
                src="/assets/images/syiok-logo-desktop.png"
                alt="QUARTZ"
                className="brand-logo"
              />
            </Link>

            <ul className="item-center">
              <li
                className={nav_active.active === "how_it_works" ? "active" : ""}
                onClick={() => history.push("/how-it-works")}
              >
                HOW TO JOIN
              </li>
              <li
                className={nav_active.active === "about_us" ? "active" : ""}
                onClick={() => history.push("/about-us")}
              >
                ABOUT US
              </li>
              <li
                className={`dropdown ${
                  nav_active.active === "full_syntetic" ||
                  nav_active.active === "semi_syntetic"
                    ? " active"
                    : ""
                }`}
              >
                PRODUCTS&nbsp;&nbsp;<i className="arrow down"></i>
                <ol>
                  <li
                    className={
                      nav_active.active === "full_syntetic" ? "active" : ""
                    }
                    onClick={() => history.push("/buy3get1")}
                  >
                    QUARTZ INEO Range
                  </li>
                  <hr className="my-0" />
                  <li
                    className={
                      nav_active.active === "full_syntetic" ? "active" : ""
                    }
                    onClick={() => history.push("/buy3get1")}
                  >
                    QUARTZ 9000 Range
                  </li>
                  <hr className="my-0" />
                  <li
                    className={
                      nav_active.active === "semi_syntetic" ? "active" : ""
                    }
                    onClick={() => history.push("/buy4get1")}
                  >
                    QUARTZ 8000 Range
                  </li>
                  <hr className="my-0" />
                  <li
                    className={
                      nav_active.active === "semi_syntetic" ? "active" : ""
                    }
                    onClick={() => history.push("/buy4get1")}
                  >
                    QUARTZ 7000 Range
                  </li>
                </ol>
              </li>
              <li
                className={nav_active.active === "promotion" ? "active" : ""}
                onClick={() => history.push("/promotion")}
              >
                EVENTS &amp; PROMOTIONS
              </li>
              <li
                className={`dropdown ${
                  nav_active.active === "contact_support" ||
                  nav_active.active === "faq"
                    ? " active"
                    : ""
                }`}
              >
                HELP&nbsp;&nbsp;<i className="arrow down"></i>
                <ol>
                  <li
                    className={
                      nav_active.active === "contact_support" ? "active" : ""
                    }
                    onClick={() => history.push("/contact-support")}
                  >
                    Customer Support
                  </li>
                  <hr className="my-0" />
                  <li
                    className={nav_active.active === "faq" ? "active" : ""}
                    onClick={() => history.push("/faq")}
                  >
                    FAQ
                  </li>
                </ol>
              </li>
            </ul>

            <ul className="right-section">
              <li className="syiok-oil">
                <span className="text-primary syiok-logo">Quartz Syiok!</span>
              </li>
              {Cookies.get("access_token") !== undefined ? (
                <Fragment>
                  <li
                    className={`inbox ${
                      nav_active.active === "inbox" ? "active" : ""
                    }`}
                    onClick={(e) => history.replace("/inbox")}
                  >
                    <i className="icon-inbox"></i>
                    <sup>{inboxIndicator}</sup>
                  </li>
                  <li
                    className={`dropdown ${
                      nav_active.active === "loyalty" ? " active" : ""
                    }`}
                  >
                    <i className="icon-user-copy"></i>
                    <ol>
                      <li
                        className={
                          nav_active.active === "loyalty" ? "active" : ""
                        }
                        onClick={() => history.push("/loyalty")}
                      >
                        Dashboard
                      </li>
                      <hr className="my-0" />
                      <li onClick={() => onLogout()}>Logout</li>
                    </ol>
                  </li>
                </Fragment>
              ) : (
                <button
                  className="tom-button primary-button"
                  onClick={() => history.push("/login")}
                  style={{ marginTop: "-0.3rem", maxWidth: "5rem" }}
                >
                  Login
                </button>
              )}
            </ul>
          </div>
        </div>
        <div className="nav-mobile">
          <div className="brand-logo">
            <Link to="/">
              <img src="/assets/images/syiok-logo-mobile.png" alt="QUARTZ" />
            </Link>
          </div>
          <div className="d-flex">
            <div className="pt-1 pr-1">
              <span className="text-primary syiok-logo">Quartz Syiok!</span>
            </div>
            {Cookies.get("access_token") !== undefined ? (
              <div className="inbox" onClick={(e) => history.replace("/inbox")}>
                <sup>{inboxIndicator}</sup>
                <i className="icon-inbox"></i>
              </div>
            ) : (
              ""
            )}
            <div className="menu-toggler" onClick={() => toggleMenu()}>
              <div className="menu-bar"></div>
            </div>
          </div>
        </div>
      </nav>
      <SidebarComponent
        toggleMenu={toggleMenu}
        sidebar_status={sidebar_status}
      ></SidebarComponent>
    </Fragment>
  );
}
