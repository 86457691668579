import React from "react";
import ForgotPasswordFormComponent from "./forgot-password-form/forgot-password-form";
import "./forgot-password.scss";
import WelcomeComponent from "../welcome/welcome";
import PopupContextProvider from "../../../provider/popup-provider";

export default function ForgotPasswordScreen(props) {
  return (
    <div className="forgot-password-screen">
      <PopupContextProvider>
        <WelcomeComponent></WelcomeComponent>
        <ForgotPasswordFormComponent></ForgotPasswordFormComponent>
      </PopupContextProvider>
    </div>
  );
}
