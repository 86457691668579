import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import "./our-products.scss";
import ProductSliderComponent from "./product-slider";

export default function OurProductsComponent(props) {
  const [tab_active, setTabActive] = useState(1);
  const history = useHistory();

  return (
    <section className="our-products-component">
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="text-md-center mb-3 mb-md-4">
                Our Offers
              </h2>
            </div>
          </div>
          <div className="tab">
            <div className="row tab-header">
              <div
                className={`col-6 tab-header-item ${
                  tab_active === 1 ? "active" : ""
                }`}
                onClick={() => {
                  setTabActive(1);
                }}
              >
                <p className="my-2">Buy 3 Get 1 Free</p>
              </div>
              <div
                className={`col-6 tab-header-item ${
                  tab_active === 2 ? "active" : ""
                }`}
                onClick={() => {
                  setTabActive(2);
                }}
              >
                <p className="my-2">Buy 4 Get 1 Free</p>
              </div>
            </div>
            <div
              className={`tab-body pt-4 ${tab_active === 1 ? "active" : ""}`}
            >
              <div className="row text-center">
                <div className="col-12 pt-5 pb-4">
                  <ProductSliderComponent
                    params={{
                      effect: "coverflow",
                      grabCursor: true,
                      centeredSlides: true,
                      slidesPerView: "8",
                      initialSlide: "3",
                      coverflowEffect: {
                        rotate: 0,
                        stretch: 10,
                        depth: 200,
                        modifier: 1,
                        slideShadows: false,
                      },
                      navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      },
                      breakpoints: {
                        300: {
                          slidesPerView: "4",
                        },
                        679: {
                          slidesPerView: "8",
                        },
                      },
                    }}
                    products={[
                      {
                        img: "/assets/images/QUARTZINEOMC35W30.png",
                        alt:
                          "Formulated with Low SAPS technology that’s fuel saving.",
                        content: ["QUARTZ INEO MC3", "(5 Litre)"],
                      },
                      {
                        img: "/assets/images/QUARTZINEOHTC5W30.png",
                        alt:
                          "Highly advanced formulation that’s suitable for diesel & turbocharged gasoline engines.",
                        content: ["QUARTZ INEO HTC", "(5 Litre)"],
                      },
                      {
                        img: "/assets/images/QUARTZ9000CTI45W30.png",
                        alt:
                          "Super premium heavy duty engine oil for old and new diesel engines, including those with EGR system.",
                        content: ["QUARTZ DIESEL", "9000 CI-4 5W30 (6 Litre)"],
                      },
                      {
                        img: "/assets/images/QUARTZ9000ENERGY0W40.png",
                        alt:
                          "Outstanding engine protection that’s a great fit for European performance cars.",
                        content: [
                          "QUARTZ 9000",
                          "ENERGY 0W-40 (4 Litre)",
                        ],
                      },
                      // {
                      //   img: "/assets/images/QUARTZRACING10W69.png",
                      //   content: ["QUARTZ RACING", "10W-60 (4 Litre)"],
                      // },
                      {
                        img: "/assets/images/QUARTZ9000FUTUREGF55W30.png",
                        alt:
                          "Formulated for hybrid or “downsized” engines with Stop & Start technologies.",
                        content: [
                          "QUARTZ 9000",
                          "FUTURE GF6 5W-30 (4 Litre)",
                        ],
                      },
                      {
                        img: "/assets/images/QUARTZ90005W50.png",
                        alt:
                          "Ultimate engine protection that gives unparalleled stability & endurance under extreme conditions.",
                        content: ["QUARTZ 9000", "5W-50 (4 Litre)"],
                      },
                      {
                        img: "/assets/images/QUARTZ90005W40.png",
                        alt:
                          "Suitable for turbo-compressed, multi-valve, direct injection diesel & gasoline engines.",
                        content: ["QUARTZ 9000", "5W-40 (4 Litre)"],
                      },
                      {
                        img: "/assets/images/QUARTZ90000FUTUREGF50W20.png",
                        alt:
                          "Formulated for hybrid or “downsized” engines with Stop & Start technologies.",
                        content: [
                          "QUARTZ 9000",
                          "FUTURE GF6 0W-20 (4 Litre)",
                        ],
                      },
                    ]}
                  ></ProductSliderComponent>
                  <p className="detail mt-5 px-4 text-center">
                    Fully synthetic oil is man-engineered, which means uniform
                    molecule sizes for less friction,{" "}
                    <br className="d-none d-md-block" /> reduced deposits,
                    additives to boost protection and clean your beloved
                    engines, and better <br className="d-none d-md-block" />{" "}
                    performance in cold and extreme weathers.
                  </p>
                </div>
              </div>
              <div className="detail-point px-4">
                <div className="row refer-button pr-4">
                  <div className="offset-2 offset-md-4 col-8 col-md-4">
                    <button
                      className="tom-button primary-button arrow px-5"
                      onClick={() => history.push("/buy3get1")}
                    >
                      LEARN MORE
                    </button>
                  </div>
                </div>
                <div className="row pb-4 text-center justify-content-md-center">
                  <div className="offset-2 offset-md-0 col-4 col-md-2 mb-4">
                    <img
                      src="/assets/images/home/optimises-performance.png"
                      alt="Optimises performance"
                      width="100%"
                    />
                    <p className="mb-0">
                      Optimises <br /> performance
                    </p>
                  </div>
                  <div className="col-4 col-md-2 mb-4">
                    <img
                      src="/assets/images/home/increses-engine-life.png"
                      alt="Increases engine life"
                      width="100%"
                    />
                    <p className="mb-0">
                      Increases <br /> engine life
                    </p>
                  </div>
                  <div className="offset-2 offset-md-0 col-4 col-md-2 mb-4">
                    <img
                      src="/assets/images/home/provide-excellent-engine-protection.png"
                      alt="Provides excellent engine protection"
                      width="100%"
                    />
                    <p className="mb-0">
                      Provides excellent <br /> engine protection
                    </p>
                  </div>
                  <div className="col-4 col-md-2">
                    <img
                      src="/assets/images/home/reduces-oil-consumption.png"
                      alt="Reduces oil consumption"
                      width="100%"
                    />
                    <p className="mb-0">
                      Reduces oil <br /> consumption
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`tab-body tab-body-2 pt-4 ${
                tab_active === 2 ? "active" : ""
              }`}
            >
              <div className="row text-center">
                <div className="col-12 pt-5 pb-4">
                  <ProductSliderComponent
                    params={{
                      effect: "coverflow",
                      grabCursor: true,
                      centeredSlides: true,
                      slidesPerView: "8",
                      initialSlide: "1",
                      coverflowEffect: {
                        rotate: 0,
                        stretch: 10,
                        depth: 200,
                        modifier: 1,
                        slideShadows: false,
                      },
                      navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      },
                      breakpoints: {
                        300: {
                          slidesPerView: "4",
                        },
                        679: {
                          slidesPerView: "8",
                        },
                      },
                    }}
                    products={[
                      {
                        img: "/assets/images/QUARTZ7000CI410W30.png",
                        alt:
                          "Ultra-high performance lubricant, specially formulated for pickups, vans, mini-buses, and other commercial vehicles.",
                        content: [
                          "QUARTZ DIESEL 7000",
                          "CI-4 10W-30 (6 Litre)",
                        ],
                      },
                      {
                        img: "/assets/images/QUARTZ8000FUTUREGF65W30.png",
                        alt:
                          "High performance multi-grade oil, suitable for for both gasoline and diesel engines & various operating conditions.",
                        content: [
                          "QUARTZ 8000",
                          "FUTURE GF6 5W-30 (4 Litre)",
                        ],
                      },
                      {
                        img: "/assets/images/QUARTZ80005W40.png",
                        alt:
                          "High performance multi-grade oil, suitable for for both gasoline and diesel engines & various operating conditions.",
                        content: ["QUARTZ 8000", "5W-40 (4 Litre)"],
                      },
                      {
                        img: "/assets/images/QUARTZ8000GF60W20.png",
                        alt:
                          "Fully-synthetic product suitable for “downsized”, Stop and Start engines, as well as hybrid engines.",
                        content: ["QUARTZ 8000 ", "FUTURE GF6 0W-20 (3 Litre)"],
                      },
                      {
                        img: "/assets/images/QUARTZ70005W30.png",
                        alt:
                          "Significant improvement to engines against wear & deposits, performances, & oil drain intervals.",
                        content: [
                          "QUARTZ 7000",
                          "FUTURE GF6 5W-30 (4 Litre)",
                        ],
                      },
                      {
                        img: "/assets/images/QUARTZ700015W50.png",
                        alt:
                          "High performance multi-grade oil, suitable for for both gasoline and diesel engines & various operating conditions.",
                        content: ["QUARTZ 7000", "15W-50 (4 Litre)"],
                      },
                      {
                        img: "/assets/images/QUARTZ700010W40.png",
                        alt:
                          "Suitable for both gasoline & diesel engines without diesel particulate filters.",
                        content: ["QUARTZ 7000", "10W-40 (4 Litre)"],
                      },
                    ]}
                  ></ProductSliderComponent>
                  <p className="mt-5 px-4 detail text-center">
                    Synthetic based oil is a mixture of synthetic oil and
                    mineral oil, which means it has some of the benefits of
                    <br className="d-none d-md-block" />
                    fully synthetic, with generally half efficiency. So why
                    synthetic based and not fully synthetic? Because sometimes
                    <br className="d-none d-md-block" />
                    it'll just do, especially for the budget-conscious, yet
                    still caring drivers.
                  </p>
                </div>
              </div>
              <div className="detail-point px-4">
                <div className="row refer-button pr-4">
                  <div className="offset-2 offset-md-4 col-8 col-md-4">
                    <button
                      className="tom-button primary-button arrow px-5"
                      onClick={() => history.push("/buy4get1")}
                    >
                      LEARN MORE
                    </button>
                  </div>
                </div>
                <div className="row pb-4 text-center justify-content-md-center">
                  <div className="col-4 col-md-2 mb-4">
                    <img
                      src="/assets/images/home/improves-engine-clenliness.png"
                      alt="Improves engine cleanliness"
                      width="100%"
                    />
                    <p className="mb-0">
                      Improves <br /> engine cleanliness
                    </p>
                  </div>
                  <div className="col-4 col-md-2 mb-4">
                    <img
                      src="/assets/images/home/increses-fuel-economy.png"
                      alt="Increases fuel economy"
                      width="100%"
                    />
                    <p className="mb-0">
                      Increases <br /> fuel economy
                    </p>
                  </div>
                  <div className="col-4 col-md-2 mb-4">
                    <img
                      src="/assets/images/home/reduces-oil-consumption.png"
                      alt="Reduces oil consumption"
                      width="100%"
                    />
                    <p className="mb-0">
                      Reduces oil <br /> consumption
                    </p>
                  </div>
                  <div className="offset-2 offset-md-0 col-4 col-md-2">
                    <img
                      src="/assets/images/home/provides-optimum-performance.png"
                      alt="Provides optimum performance"
                      width="100%"
                    />
                    <p className="mb-0">
                      Provides optimum <br /> performance
                    </p>
                  </div>
                  <div className="col-4 col-md-2">
                    <img
                      src="/assets/images/home/advanced-protection.png"
                      alt="Advanced protection leads to prolonged engine life"
                      width="100%"
                    />
                    <p className="mb-0">
                      Advanced protection
                      <br />
                      leads to prolonged
                      <br />
                      engine life
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
