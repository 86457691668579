import React, { useContext, useRef, useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import Cookie from "js-cookie";
import "./login-form.scss";
import { loginAPI } from "./login-api";
import { apiURL, fb, google, recaptchaKey } from "../../../../config/config";
import { PopupContext } from "../../../../provider/popup-provider";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { validation_message } from "../../../../data/validation_message";
import { UserContext } from "../../../../provider/user-provider";
import { togglePassword } from "../../../../helpers/toggle-password";
import SpinnerComponent from "../../../../templates/global-component/spinner-component/spinner-component";
import ReCAPTCHA from "react-google-recaptcha";

export default function LoginFormComponent(props) {
  const { popup, setPopup, closePopup } = useContext(PopupContext);
  const { getUserProfile } = useContext(UserContext);

  const recaptchaRef = useRef();

  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    setIsLoading(true);
    // run login service
    if (!isLoading) {
      const loginResult = await loginAPI(
        `${data.mobile_ext_no}${data.mobile_no}`,
        data.password
      );

      if (loginResult.status_code == 200) {
        // set cookie
        Cookie.set("access_token", loginResult.result.access_token, {
          expires: 180,
        });
        Cookie.set("refresh_token", loginResult.result.refresh_token, {
          expires: 180,
        });

        // Update user context
        await getUserProfile();

        // forward to loyalty page
        window.location.href = props.redirect_to;
        // history.replace("/loyalty");
      } else {
        if (loginResult.status_code == 500) {
          // popup :Note
          let popupVal = Object.assign({}, popup);
          popupVal.open = true;
          popupVal.content = ["Invalid mobile number or password"];
          popupVal.img = "danger-icon";
          popupVal.buttons = [
            {
              type: "primary",
              text: "OK",
              tap: () => {
                closePopup();
              },
            },
          ];

          setPopup(popupVal);
          setIsLoading(false);
        } else if (loginResult.status_code == 'internet') {
          let popupVal = Object.assign({}, popup);
          popupVal.open = true;
          popupVal.content = [
            "Oh no! Seems like you're not connected to the internet. Make sure your WiFi or mobile data is turned on.",
          ];
          popupVal.img = "";
          popupVal.buttons = [
            {
              type: "primary",
              text: "OK",
              tap: () => {
                closePopup();
              },
            },
          ];
          setPopup(popupVal);
          setIsLoading(false);
        }
      }
    }
  };

  const FacebookLoginAPI = async (data) => {
    let params = {
      accessToken: data.accessToken,
      tokenId: data.id,
      userID: data.userID,
      email: data.email,
    };

    try {
      const response = await axios.post(`${apiURL}/api/login/facebook`, params);

      if (response.data.status_code == 200) {
        let result = response.data.result;

        // set cookie
        Cookie.set("access_token", result.access_token, { expires: 180 });
        Cookie.set("refresh_token", result.refresh_token, { expires: 180 });

        // Update user context
        await getUserProfile();

        window.location.href = "/loyalty";
        // history.replace("/loyalty");
      } else {
        console.log(response.data);
        // popup :Note
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GoogleLoginAPI = async (data) => {
    if (typeof data.profileObj === "undefined") {
      return false;
    }

    let params = Object.assign(data.profileObj, {
      accessToken: data.accessToken,
      userID: data.googleId,
      tokenId: data.tokenId,
    });

    try {
      const response = await axios.post(`${apiURL}/api/login/google`, params);

      if (response.data.status_code == 200) {
        let result = response.data.result;

        // set cookie
        Cookie.set("access_token", result.access_token, { expires: 180 });
        Cookie.set("refresh_token", result.refresh_token, { expires: 180 });

        // Update user context
        await getUserProfile();

        window.location.href = "/loyalty";
      } else {
        console.log(response.data);
        // popup :Note
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeRecaptcha = () => {
    const recaptchaValue = recaptchaRef.current.getValue();
    document.getElementById("recaptcha").checked = true;
  };

  return (
    <section className="login-form">
      <div className="container">
        <div className="row mb-4">
          <div className="col-6 text-left">
            <h2 className="mb-0 title">
              Login
            </h2>
          </div>
          <div className="col-6 text-right">
            <p className="mb-0 pt-2 text-responsive text-sm">
              New member?{" "}
              <Link to="/register" className="text-primary">
                Register here</Link>
            </p>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="row mb-4">
          <div className="offset-1 offset-md-2 col-10 col-md-8 mt-md-3 mb-md-4">
            <div className="mobile-no-input">
              <div className="input-control">
                <select
                  ref={register}
                  id="mobile-ext-no"
                  className="tom-input"
                  name="mobile_ext_no"
                  onChange={() => {
                    document.getElementById("mobile-no").value = "";
                  }}
                >
                  <option value="60">MY: +60</option>
                  <option value="65">SG: +65</option>
                  <option value="65">BN: +673</option>
                  <option value="65">TH: +66</option>
                </select>
                <input
                  ref={register({
                    required: {
                      value: true,
                      message: `*${validation_message.login.mobile_no.require.message}`,
                    },
                    minLength: {
                      value: 7,
                      message: `*${validation_message.login.mobile_no.minLength.message}`,
                    },
                    maxLength: {
                      value: 13,
                      message: `*${validation_message.login.mobile_no.maxLength.message}`,
                    },
                    pattern: {
                      value: `/^[0-9]{7,13}$/`,
                      message: `*${validation_message.login.mobile_no.pattern.message}`,
                    },
                  })}
                  type="number"
                  inputMode="decimal"
                  className="tom-input"
                  id="mobile-no"
                  name="mobile_no"
                  onChange={(e) => {
                    if (
                      document.getElementById("mobile-ext-no").value == 60 &&
                      e.target.value.charAt(0) === "0"
                    ) {
                      return (e.target.value = e.target.value.slice(1));
                    }
                    if (e.target.value.toString().length > 13) {
                      return (e.target.value = e.target.value.substr(0, 13));
                    }
                  }}
                />
                <small className="error">
                  <ErrorMessage errors={errors} name="mobile_no" />
                </small>
              </div>
            </div>
          </div>
          <div className="offset-1 offset-md-2 col-10 col-md-8">
            <div className="input-control">
              <input
                ref={register({
                  required: {
                    value: true,
                    message: `*${validation_message.login.password.require.message}`,
                  },
                  pattern: {
                    value: /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[!@#$%^&*(),.?":{}|<>_~`+-]).{8,}$/,
                    message: `*${validation_message.login.password.pattern.message}`,
                  },
                })}
                type="password"
                id="password"
                className="tom-input"
                name="password"
                placeholder="Password"
              />
              <span
                className="icon-eye"
                onClick={(e) => {
                  togglePassword(e, "password");
                }}
              ></span>
              <small className="error">
                <ErrorMessage errors={errors} name="password" />
              </small>
            </div>
          </div>
          <div className="offset-1 offset-md-2 col-10 col-md-8 mt-3">
            <div className="input-control">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={recaptchaKey.sitekey}
                onChange={onChangeRecaptcha}
              />
              <input
                type="checkbox"
                ref={register({
                  required: {
                    value: true,
                    message: `*Validation error`,
                  },
                })}
                id="recaptcha"
                name="recaptcha"
                className="d-none"
              />
              <small className="error mt-1">
                <ErrorMessage errors={errors} name="recaptcha" />
              </small>
            </div>
          </div>
          <div className="offset-1 offset-md-2 col-10 col-md-8 text-right mb-4">
            <Link
              to="/forgot-password"
              className="text-primary text-responsive text-sm"
            >
              Forgot password?
            </Link>
          </div>
          <div className="offset-1 offset-md-2 col-10 col-md-8 text-center">
            <button className="tom-button primary-button" disabled={isLoading}>
              LOGIN{" "}
              {isLoading ? (
                <SpinnerComponent
                  fontColor={"text-white"}
                  dimensions={{ mobile: "14px", desktop: "1rem" }}
                ></SpinnerComponent>
              ) : (
                ""
              )}
            </button>
          </div>
        </form>

        {/* <div className="row social-button">
          <div className="offset-1 offset-md-2 col-10 col-md-8 mb-4">
            <FacebookLogin
              appId={fb.appId}
              autoLoad={false}
              fields="name,email,picture,birthday"
              callback={FacebookLoginAPI}
              disableMobileRedirect={true}
              render={(renderProps) => (
                <button
                  className="tom-button facebook-button"
                  onClick={renderProps.onClick}
                >
                  <i className="icon-facebook-icon"></i> LOGIN WITH FACEBOOK
                </button>
              )}
            />
          </div>
          <div className="offset-1 offset-md-2 col-10 col-md-8">
            <GoogleLogin
              clientId={google.clientId}
              onSuccess={GoogleLoginAPI}
              onFailure={(data) => console.log(data)}
              scope="https://www.googleapis.com/auth/user.birthday.read"
              render={(renderProps) => (
                <button
                  className="tom-button google-button"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <img
                    src="/assets/images/icon-google.png"
                    style={{
                      position: "relative",
                      width: "1rem",
                      top: "-0.1rem",
                    }}
                  />{" "}
                  LOGIN WITH GOOGLE
                </button>
              )}
            ></GoogleLogin>
          </div>
        </div> */}
      </div>
    </section>
  );
}
