import axios from "axios";
import querystring from "querystring";
import { apiURL } from "../../../../config/config";

export const loginAPI = async (mobile_number, password) => {
  try {
    const response = await axios.post(
      `${apiURL}/api/login`,
      querystring.stringify({
        mobile_number: mobile_number,
        password: password,
      })
    );
    return response.data;
  } catch (error) {
    if (error?.message == 'Network Error') {
      return {
        status_code: 'internet'
      }
    } else {
      return {
        status_code: 500
      }
    }
  }
};