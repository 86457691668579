import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../../../../provider/user-provider";
import "./header-section.scss";

export default function HeaderSectionComponent(props) {
  const { userProfile } = useContext(UserContext);

  return (
    <section className="header-section-component">
      <div className="container">
        <div className="row px-md-4">
          <div className="col-6">
            <p className="mb-0 text-responsive text-md text-primary">
              Hello <br />
              <b className="text-responsive text-md">{userProfile.fullname}</b>
            </p>
          </div>
          <div className="col-6 text-right">
            <Link to="/edit-profile" className="edit-profile">
              <p className="text-responsive text-sm text-primary mb-0">
                Edit Profile <i className="icon-pencil"></i>
              </p>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
