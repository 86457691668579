import React from "react";
import "./blank-template.scss";

export const BlankTemplate = (props) => {
  return (
    <div className="blank-template">
      {props.children}
    </div>
  );
};

export default BlankTemplate;
