import React, { useContext } from "react";
import "./inbox-list.scss";
import { InboxContext } from "../../../../provider/inbox-provider";
import { formatDateWithTime } from "../../../../helpers/moment-helper";

export default function InboxListSection(props) {
  const { loadingIndicator, messages } = useContext(InboxContext);

  const onReadMessage = (id, is_read) => {
    if (
      !document.getElementById(`message-${id}`).classList.contains("active")
    ) {
      document.getElementById(`message-${id}`).classList.add("active");
      if (!is_read) {
        document.getElementById(`loading-${id}`).classList.add("loading");
        document.getElementById(`loading-${id}`).classList.remove("loading");
      }
    } else {
      document.getElementById(`message-${id}`).classList.remove("active");
    }
  };

  return (
    <section className="inbox-list">
      <div className="container">
        <div className="row">
          <div className="offset-0 offset-md-2 col-12 col-md-8 px-md-0 mb-md-2">
            <p>All Messages ({messages.length})</p>
          </div>
        </div>
        <div className="row" style={{ position: "relative" }}>
          {loadingIndicator ? (
            <div className="offset-0 offset-md-2 col-12 col-md-8 px-md-0 mb-2 spinner">
              <center>
                <div className="spinner-border text-danger" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </center>
            </div>
          ) : (
              messages.map(function (notification, index) {
                return (
                  <InboxListItem
                    key={index}
                    notification={notification}
                    id={index}
                    onReadMessage={onReadMessage}
                  ></InboxListItem>
                );
              })
            )}
        </div>
      </div>
    </section>
  );
}

function InboxListItem(props) {
  let notification_date = formatDateWithTime(props.notification.created_date);
  let status_indicator = props.notification.is_read ? "" : " unread";

  return (
    <div className="offset-0 offset-md-2 col-12 col-md-8 px-md-0 mb-3">
      <div className="card" id={`message-${props.id}`}>
        <div className="card-spinner" id={`loading-${props.id}`}>
          <div className="spinner-border text-danger " role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
        <div
          onClick={() => {
            props.onReadMessage(props.id, props.notification.is_read);
          }}
        >
          <div className="d-flex justify-content-between mb-2">
            <p className="inbox-title">Syiok!</p>
            <div className="d-flex">
              <p className="mr-2 mr-md-4 date">{notification_date}</p>
              <div className={"status-indicator" + status_indicator}></div>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div style={{ width: "90%" }}>
              <p>
                <b>{props.notification.subject}</b>
              </p>
              <p>{props.notification.message}</p>
            </div>
            <div style={{ width: "10%", position: "relative" }}>
              <div
                style={{ position: "absolute", bottom: "5px", right: "0" }}
                className="arrow-bottom"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
