import React from "react";
import TopSectionScreen from "../../general-screen/top-section/top-section";
import EditProfileForm from "./edit-profile-form/edit-profile-form";
import "./edit-profile.scss";

export default function EditProfileScreen(props) {
  return (
    <div className="edit-profile-screen">
      <TopSectionScreen title="Edit Profile"></TopSectionScreen>
      <EditProfileForm></EditProfileForm>
    </div>
  );
}
