import React, { useContext } from "react";
import "./sidebar-item.scss";
import { Link, useHistory } from "react-router-dom";
import Cookie from "js-cookie";
import { NavActiveContext } from "../../../../provider/navbar-provider";
import { UserContext } from "../../../../provider/user-provider";
import { PopupContext } from "../../../../provider/popup-provider";

export default function SidebarItemComponent(props) {
  const { popup, setPopup } = useContext(PopupContext);
  const { nav_active } = useContext(NavActiveContext);
  const { logout } = useContext(UserContext);

  const history = useHistory();

  const onDropdown = () => {
    const inputControl = document.getElementById("products-list-item")
      .parentElement;
    if (inputControl.classList.contains("active") === true) {
      inputControl.classList.remove("active");
    } else {
      inputControl.classList.add("active");
    }
  };

  const onLogout = () => {
    let content = ["Are you sure you want to logout?"];
    let imageIcon = "danger-icon";
    let buttons = [
      {
        type: "default",
        text: "No",
        tap: () => {
          let popupVal = Object.assign({}, popup);
          popupVal.open = false;
          setPopup(popupVal);
        },
      },
      {
        type: "primary",
        text: "YES",
        tap: () => {
          logout();
        },
      },
    ];

    props.closeSidebar();

    let popupVal = Object.assign({}, popup);
    popupVal.open = true;
    popupVal.img = imageIcon;
    popupVal.content = content;
    popupVal.buttons = buttons;
    setPopup(popupVal);
  };

  return (
    <div className="sidebar-item-component">
      <div className="sidebar-item-header">
        <div className="close-sidebar" onClick={() => props.closeSidebar()}>
          <span></span>
        </div>
        <Link to="/">
          <span className="text-primary syiok-logo">Quartz Syiok!</span>
        </Link>
      </div>
      <ul>
        <li
          className={nav_active.active === "home" ? "active" : ""}
          onClick={() => history.push("/")}
        >
          <i className="icon-home-run"></i>Home
        </li>
        <li
          className={nav_active.active === "how_it_works" ? "active" : ""}
          onClick={() => history.push("/how-it-works")}
        >
          <i className="icon-badge"></i>How To Join
        </li>
        <li
          className={nav_active.active === "about_us" ? "active" : ""}
          onClick={() => history.push("/about-us")}
        >
          <i className="icon-drop"></i>About US
        </li>
        {Cookie.get("access_token") !== undefined ? (
          <li
            className={nav_active.active === "loyalty" ? "active" : ""}
            onClick={() => history.push("/loyalty")}
          >
            <i className="icon-user-copy"></i>My Dashboard
          </li>
        ) : (
            ""
          )}
        <li
          className={nav_active.active === "promotion" ? "active" : ""}
          onClick={() => history.push("/promotion")}
        >
          <i className="icon-megaphone"></i>Events &amp; Promotions
        </li>
        <li
          className={`parent ${nav_active.active === "full_syntetic" ||
              nav_active.active === "semi_syntetic"
              ? " active"
              : ""
            }`}
        >
          <div id="products-list-item" onClick={() => onDropdown()}>
            <i className="icon-bottle"></i>Products
            <span className="arrow-dropdown"></span>
          </div>
          <ol>
            <li
              className={nav_active.active === "full_syntetic" ? "active" : ""}
              onClick={() => history.push("/buy3get1")}
            >
              QUARTZ INEO Range
            </li>
            <li
              className={nav_active.active === "full_syntetic" ? "active" : ""}
              onClick={() => history.push("/buy3get1")}
            >
              QUARTZ 9000 Range
            </li>
            <li
              className={nav_active.active === "semi_syntetic" ? "active" : ""}
              onClick={() => history.push("/buy4get1")}
            >
              QUARTZ 8000 Range
            </li>
            <li
              className={nav_active.active === "semi_syntetic" ? "active" : ""}
              onClick={() => history.push("/buy4get1")}
            >
              QUARTZ 7000 Range
            </li>
          </ol>
        </li>
        <li
          className={nav_active.active === "workshops_locator" ? "active" : ""}
          onClick={() => history.push("/workshops-locator")}
        >
          <i className="icon-placeholder"></i>Workshop Locator
        </li>
        <li
          className={nav_active.active === "faq" ? "active" : ""}
          onClick={() => history.push("/faq")}
        >
          <i className="icon-question"></i>FAQ
        </li>
        <li
          className={nav_active.active === "contact_support" ? "active" : ""}
          onClick={() => history.push("/contact-support")}
        >
          <i className="icon-phone-call"></i>Customer Support
        </li>
        {Cookie.get("access_token") !== undefined ? (
          <li onClick={() => onLogout()} style={{ cursor: "pointer" }}>
            <span>
              <i className="icon-logout"></i>Logout
            </span>
          </li>
        ) : (
            ""
          )}
      </ul>
    </div>
  );
}
