export const downloadPdf = (url) => {
  var link = document.createElement("a");
  link.href = `/assets/images/TDS/${url}.pdf`;
  link.download = `${url}.pdf`;
  link.dispatchEvent(new MouseEvent("click"));
};

export default {
  downloadPdf,
};
