import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { WorkshopLocatorContext } from "../map-context";
import "./autocomplete-component.scss";

class Autocomplete extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array),
  };

  static defaultProps = {
    suggestions: [],
  };

  static context = WorkshopLocatorContext;

  constructor(props) {
    super(props);

    this.state = {
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: "",
    };
  }

  onChange = (e) => {
    const { suggestions } = this.props;
    const userInput = e.currentTarget.value;

    const filteredSuggestions = suggestions.filter(
      (suggestion) =>
        suggestion.workshop_name
          .toLowerCase()
          .indexOf(userInput.toLowerCase()) > -1 ||
        suggestion.workshop_address
          .toLowerCase()
          .indexOf(userInput.toLowerCase()) > -1
    );

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value,
    });
  };

  onClick = (e) => {
    const { suggestions, userInput } = this.props;

    const filtered_position = suggestions.filter((x) => x.id === e)[0];
    let locatorInfo = Object.assign({}, this.context.locator);
    locatorInfo.center_position.lat = filtered_position.latitude;
    locatorInfo.center_position.log = filtered_position.longitude;
    this.context.setLocator(locatorInfo);

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: filtered_position.workshop_name,
    });
  };

  render() {
    const {
      onChange,
      onClick,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput,
      },
    } = this;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li
                  className={className}
                  key={index}
                  onClick={() => onClick(suggestion.id)}
                  id={`${suggestion.id}`}
                >
                  {suggestion.workshop_name}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div className="no-suggestions">
            <em>No suggestions..</em>
          </div>
        );
      }
    }

    return (
      <Fragment>
        <input
          type="text"
          onChange={onChange}
          value={userInput}
          id="search-input"
          className="search-input"
        />
        <i className="icon-loupe" />
        {suggestionsListComponent}
      </Fragment>
    );
  }
}

Autocomplete.contextType = WorkshopLocatorContext;

export default Autocomplete;
