export const apiURL = 'https://www.mcliqonapps.net:8840';

export const appURL = 'https://mcliqonapps.net:8841';

export const excludeUrls= ['/api/login'];

export const fb = {
    appId: "672774986653132",
}

export const google = {
    clientId: "584332170638-2c01lnng5cnufeg1og64ktgtr3rhisi6.apps.googleusercontent.com",
}

export const recaptchaKey = {
    sitekey: "6Le4EPQaAAAAAFaerO_OfE0JFklhNb_zDRWAN3Ah",
}

// export const gtmID = "GTM-K4TKSMP";
export const gtmID = "GTM-TM25DJJ";
export const fbAdID = "";

//  Remote logging configs

export const logger_host = 'localhost';
export const logger_port = '3000';
export const logger_path = 'path';