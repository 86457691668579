import React, { Fragment, useContext } from "react";
import { formatDate } from "../../../../../helpers/moment-helper";
import { LoyaltyContext } from "../../../../../provider/loyalty-provider";
import "./service-notification-component.scss";

export default function ServiceNotificationComponent(props) {
  const { nextService } = useContext(LoyaltyContext);

  var next_service_info;
  var mileage;
  if (nextService != null) {
    next_service_info =
      nextService.model_brand +
      " " +
      nextService.model_name +
      " (" +
      nextService.plate_number +
      ") - " +
      nextService.next_mileage.toLocaleString() +
      "km or " +
      formatDate(nextService.next_service_date);
      mileage = nextService.next_mileage;
  }

  return (
    <Fragment>
      {nextService != null && Array.isArray(nextService.services) && nextService.services.length ? (
        <section className="service-notification-component">
          <div className="container">
            <div className="row px-md-4">
              <div className="col-11 col-md-12">
                <p className="text-responsive text-md text-white mb-0">
                  Your next service: <br />
                  <b>{next_service_info}</b>
                </p>
              </div>
              <div className="col-1"></div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </Fragment>
  );
}
