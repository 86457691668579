import React, { useContext, useEffect } from "react";
import Cookies from 'js-cookie';
import { UserContext } from "../../../provider/user-provider";

export default function LogoutScreen() {
  const { setUserProfile } = useContext(UserContext);
  useEffect(() => {
    Cookies.remove('access_token');
    Cookies.remove('refresh_token');
    sessionStorage.clear();
    localStorage.clear();
    setUserProfile({
        address: null,
        country: null,
        birth_date: null,
        email: null,
        mobile_number: null,
        fullname: null,
        password: null,
        postal_code: null,
        state: null,
        auth_provider: null,
        referral_code: null,
    });
    window.location.href = "/login";
  }, []);

  return (
    <div></div>
  );
}
