import { fullOilInfo, semiOilInfo } from "../data/oil_info";

export function oilRankingList() {
  const list = fullOilInfo.concat(semiOilInfo);

  const result = list.map(y => { return y.rank }).reduce((result, field, index) => {
    result[list.map(x => { return x.code })[index]] = field;
    return result;
  }, {});

  return result;
}


export function oilRanking(current_oil, choose_oil) {
  var oilranking = oilRankingList();

  if (oilranking[current_oil] !== oilranking[choose_oil]) {
    return true;
  }
  return false;
}

function freeOil(current_oils) {
  const _free_oil = Object.values(Object.keys(oilRankingList())
    .filter(key => current_oils.includes(key))
    .reduce((obj, key) => {
      obj[key] = oilRankingList()[key];
      return obj;
    }, {})
  );

  return getKeyByValue(oilRankingList(), Math.min(..._free_oil));
}

function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

export function freeOilRangeList(current_oils) {
  const oil_rank_list = Object.keys(oilRankingList()).map((key) => {
    return { oil: key, rank: oilRankingList()[key] };
  });

  const free_oil = freeOil(current_oils);
  const free_range = oil_rank_list
    .filter((x) => x.rank == oilRankingList()[free_oil])
    .map((x) => {
      return x.oil;
    });

  const oil_range = free_range.filter(x => current_oils.find(current =>
    current == x
  ));

  return oil_range.filter((item, pos) => oil_range.indexOf(item) === pos)
}

export function freeOilRangeCopy(current_oils) {
  const list = fullOilInfo.concat(semiOilInfo);

  const free_oil = freeOil(current_oils);
  const free_list = freeOilRangeList(current_oils);
  
  let copy = '';
  free_list.map((x, index) => {
    copy = copy + list.find(y => y.code == x).be_name;
    if (free_list.length > 1 && free_list.length > index + 1) {
      copy = copy + " OR "
    }
  });

  return copy;
}
