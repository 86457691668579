import React, { useContext, useState } from "react";
import QRCode from "react-qr-code";
import "./step-3-form.scss";
import StepBarComponent from "../../step-bar-component/step-bar-component";
import { AddServiceAPI } from "../../add-service-api";
import StepTwoComponent from "../../step-2/step-2";
import StepFourComponent from "../../step-4/step-4";
import { AddServiceContext } from "../../../../../../provider/add-service-provider";
import { PopupContext } from "../../../../../../provider/popup-provider";
import SpinnerComponent from "../../../../../../templates/global-component/spinner-component/spinner-component";
import ProgressBar from "../../../../../../share-component/progressbar/progressbar";
import axios from 'axios';
import Cookies from 'js-cookie';
import { apiURL } from '../../../../../../config/config';
import { errorMessageHandler } from "../../../../../../helpers/error-handler";

export default function StepThreeFormComponent(props) {
  const { service, setService } = useContext(AddServiceContext);
  const { popup, setPopup, closePopup } = useContext(PopupContext);

  const [isLoading, setIsLoading] = useState(false);
  const [file_extension, setFileExtension] = useState("");

  const [progress, setProgress] = useState(0)

  const filevalidation = () => {
    const fi = document.getElementById("upload-image-input");
    const input_image = document.querySelector("#input-image");
    const output_image = document.querySelector("#output-image");
    if (fi.files.length > 0) {
      for (let i = 0; i <= fi.files.length - 1; i++) {
        const fsize = fi.files.item(i).size;
        const file = Math.round(fsize / 1024);
        let file_extension = getExtension(fi.value);
        setFileExtension(file_extension);
        if (file >= 10000) {
          const popupVal = Object.assign({}, popup);
          popupVal.open = true;
          popupVal.img = "danger-icon";
          popupVal.content = [
            "Please ensure your receipt format is in JPEG, PNG or PDF and the file size DOES NOT exceed 10 MB",
          ];
          popupVal.buttons = [
            {
              type: "primary",
              text: "Close",
              tap: () => {
                closePopup();
              },
            },
          ];
          setPopup(popupVal);
        } else {
          var reader = new FileReader();
          reader.onload = function () {
            file_extension === 'pdf' ? output_image.src = "/assets/images/PDF_file_icon.svg" :
              output_image.src = reader.result;
            input_image.src = "";
            output_image.style.display = "block";
            input_image.parentElement.style.display = "none";
          };
          reader.readAsDataURL(fi.files[0]);
        }
      }
    } else {
      output_image.src = "";
      input_image.src = "/assets/images/icon-upload.png";
      output_image.style.display = "none";
      input_image.parentElement.style.display = "block";
      setFileExtension("");
    }
  };

  const uploadImage = () => {
    const upload_image_input = document.getElementById("upload-image-input");
    upload_image_input.click();
  };

  function getExtension(filename) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  }

  const onSubmitForm = async () => {
    setIsLoading(true);
    setProgress(0);
    if (!isLoading) {
      const fi = document.getElementById("upload-image-input");
      if (fi.files.length > 0) {
        let serviceInfo = Object.assign({}, service);
        serviceInfo.receipt_img = fi.files[0];

        let formData = new FormData();
        formData.append("vehicle_guid", serviceInfo.guid);
        formData.append("current_mileage", serviceInfo.current_mileage);
        formData.append("workshop_id", serviceInfo.workshop_id.floating);
        formData.append("oil", serviceInfo.oil.float);
        formData.append("total_bottle", serviceInfo.bottle_no);
        formData.append("receipt_img", fi.files[0]);

        try {
          const response = await axios.post(
            `${apiURL}/api/service`,
            formData,
            {
              headers: {
                'Authorization': 'Bearer ' + Cookies.get('access_token')
              },
              onUploadProgress: data => {
                //Set the progress value to show the progress bar
                setProgress(Math.round((100 * data.loaded) / data.total))
              },
            }
          );

          if (response.data.status_code == 200) {
            let result = response.data.result;

            serviceInfo.transaction_code = result.transaction_code;
            serviceInfo.oil.float = result.oil_name;
            serviceInfo.service_date = result.service_date;
            serviceInfo.screen = <StepFourComponent></StepFourComponent>;
            setService(serviceInfo);
          } else {
            const popupVal = Object.assign({}, popup);
            popupVal.open = true;
            popupVal.img = "danger-icon";

            let msg = errorMessageHandler(response.data);
            popupVal.content = [msg];
            popupVal.buttons = [
              {
                type: "primary",
                text: "Close",
                tap: () => {
                  closePopup();
                  setProgress(0);
                },
              },
            ];
            setPopup(popupVal);
            setIsLoading(false);
          }
        } catch (error) {
          if (error?.message == 'Network Error') {
            const popupVal = Object.assign({}, popup);
            popupVal.open = true;
            popupVal.content = [
              "Oh no! Seems like you're not connected to the internet. Make sure your WiFi or mobile data is turned on.",
            ];
            popupVal.img = "";
            popupVal.buttons = [
              {
                type: "primary",
                text: "TRY AGAIN",
                tap: () => {
                  closePopup();
                  setProgress(0);
                },
              },
            ];
            setPopup(popupVal);
            setIsLoading(false);
          } else {
            const popupVal = Object.assign({}, popup);
            popupVal.open = true;
            popupVal.content = [error.toString()];
            popupVal.img = "";
            popupVal.buttons = [
              {
                type: "primary",
                text: "TRY AGAIN",
                tap: () => {
                  closePopup();
                  setProgress(0);
                },
              },
            ];
            setPopup(popupVal);
            setIsLoading(false);
          }
        }
      } else {
        const popupVal = Object.assign({}, popup);
        popupVal.open = true;
        popupVal.img = "danger-icon";
        popupVal.content = ["Please upload your receipt"];
        popupVal.buttons = [
          {
            type: "primary",
            text: "Close",
            tap: () => {
              closePopup();
            },
          },
        ];
        setPopup(popupVal);
        setIsLoading(false);
      }
    }
  };

  function goBack() {
    let serviceInfo = Object.assign({}, service);
    serviceInfo.screen = <StepTwoComponent></StepTwoComponent>;
    setService(serviceInfo);
  }

  return (
    <section className="step-three-form-component">
      <div className="container">
        <div className="row text-center">
          <div className="offset-1 offset-md-2 col-10 col-md-8 mb-3">
            <h2 className="text-center">STEP 3</h2>
          </div>
          <div className="offset-1 offset-md-2 col-10 col-md-8 mb-3">
            <StepBarComponent active="3"></StepBarComponent>
          </div>
          <div className="offset-1 offset-md-2 col-10 col-md-8 mb-3">
            <p className="mb-0">Upload your receipt</p>
          </div>
          <div className="offset-1 offset-md-3 col-10 col-md-6 mb-3">
            <div className="upload-img" onClick={() => uploadImage()}>
              <div className="p-5">
                <img
                  id="input-image"
                  width="100%"
                  src="/assets/images/icon-upload.png"
                />
              </div>
              <img style={{ display: "none" }} id="output-image" width="100%" />
            </div>
            <div className="input-control">
              <input
                style={{ display: "none" }}
                type="file"
                id="upload-image-input"
                name="upload image"
                accept="image/png, image/jpeg, application/pdf"
                onChange={() => filevalidation()}
              />
              <small></small>
            </div>
            <p className="file-extension">
              {file_extension?.length > 0
                ? `File Extension: ${file_extension}`
                : ""}
            </p>
          </div>
          <div className="offset-1 offset-md-2 col-10 col-md-8 mb-3">
            <p>
              Take a photo of your receipt and upload it here. JPEG, PNG, and
              PDF formats are supported and please ensure the file size DOES NOT
              exceed 10 MB.
            </p>
            <p className="mb-0">
              Make sure the photo is clear with itemised purchase of Quartz engine oil and amount spent.
            </p>
          </div>
          {progress > 0 ?
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-2">
              <ProgressBar percentage={progress} />
            </div>
            : ""}
          <div className="offset-1 offset-md-2 col-10 col-md-8 mb-3">
            <button
              className="tom-button primary-button"
              onClick={() => onSubmitForm()}
              disabled={isLoading}
            >
              Next
              {isLoading ? (
                <SpinnerComponent
                  fontColor={"text-white"}
                  dimensions={{ mobile: "14px", desktop: "1rem" }}
                ></SpinnerComponent>
              ) : (
                ""
              )}
            </button>
          </div>
          <div className="offset-1 offset-md-2 col-10 col-md-8 mb-3">
            <button
              className="tom-button default-button"
              onClick={() => goBack()}
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
