import React from "react";
import ServiceDescriptionComponent from "./service-description/service-description";
import QRGenerateComponent from "./qr-generate/qr-generate";
import "./step-4.scss";
import PopupContextProvider from "../../../../../provider/popup-provider";

export default function StepFourComponent(props) {
  return <div className="step-four-component">
    <PopupContextProvider>
      <ServiceDescriptionComponent></ServiceDescriptionComponent>
      <QRGenerateComponent></QRGenerateComponent>
    </PopupContextProvider>
  </div>;
}
