export const dashboard_routes = [
  {
    path: "/register/survey",
  },
  {
    path: "/edit-profile",
  },
  {
    path: "/inbox",
  },
  {
    path: "/loyalty",
  },
  {
    path: "/loyalty/service-information",
  },
  {
    path: "/loyalty/add-service",
  },
  {
    path: "/loyalty/add-new-vehicle",
  },
  {
    path: "/refer-and-earn",
  },
  {
    path: "/logout",
  },
];

export const login_routes = [
  {
    path: "/login",
  },
  {
    path: "/forgot-password",
  },
  {
    path: "/forgot-password/tac-number",
  },
  {
    path: "/register",
  },
  // {
  //   path: "/register/email",
  // },
  {
    path: "/register/tac-number",
  },
];

export const general_routes = [
  {
    path: "/",
  },
  {
    path: "/about-us",
  },
  {
    path: "/how-it-works",
  },
  {
    path: "/faq",
  },
  {
    path: "/promotion",
  },
  {
    path: "/subscribe",
  },
  {
    path: "/contact-support",
  },
  {
    path: "/workshops-locator",
  },
  {
    path: "/buy3get1",
  },
  {
    path: "/buy4get1",
  },
];
