import React from "react";
import TopSectionScreen from "../../general-screen/top-section/top-section";
import ContentSection from "./content-section/content-section";
import "./refer-earn.scss";

export default function ReferEarnScreen(props) {
    return (
        <div className="refer-earn-screen">
            <TopSectionScreen title="Refer &amp; Earn"></TopSectionScreen>
            <ContentSection></ContentSection>
        </div>
    );
}
