import React from "react";
import { useHistory } from "react-router-dom";
import "./refer-friend.scss";

export default function ReferFriend(props) {
  const history = useHistory();

  return (
    <section className="refer-friend">
      <div className="container">
        <div className="row py-4">
          <div className="col-12">
            <h2 className="text-left text-md-center mb-3 mb-md-4">
              The More The Merrier
            </h2>
          </div>
          <div className="col-12">
            <div className="card">
              <div className="row h-100">
                <div className="col-7 col-md-6 pl-0 col-left">
                  <img
                    src="/assets/images/TE-DESKTOP-MGM-BANNER.png"
                    alt="Refer a friend and get attractive rewards!"
                    width="100%"
                    className="d-none d-md-block"
                  />
                  <img
                    src="/assets/images/TE-MOBILE-MGM-BANNER.png"
                    alt="Refer a friend and get attractive rewards!"
                    width="100%"
                    className="d-md-none"
                  />
                </div>
                <div className="col-5 col-md-6 h-100 my-auto pr-4 pl-0 pl-md-3">
                  <h3 className="text-primary">
                    <b>Pssst!</b>
                  </h3>
                  <p>Refer a friend and get attractive rewards!</p>
                  <button
                    className="tom-button primary-button arrow"
                    style={{"maxWidth": "13rem"}}
                    onClick={() => history.push("/refer-and-earn")}
                  >
                    FIND OUT MORE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
