import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { freeOilRangeCopy} from "../../../../../../helpers/oil-ranking";
import { AddServiceContext } from "../../../../../../provider/add-service-provider";
import { PopupContext } from "../../../../../../provider/popup-provider";
import { UserContext } from "../../../../../../provider/user-provider";
import { CheckServiceAPI } from "../../add-service-api";
import StepBarComponent from "../../step-bar-component/step-bar-component";
import "./service-description.scss";

export default function ServiceDescriptionComponent(props) {
  const { service } = useContext(AddServiceContext);
  const { popup, setPopup } = useContext(PopupContext);
  const { userProfile } = useContext(UserContext);
  const history = useHistory();

  var interval;

  useEffect(() => {
    checkService();
    // Unmount
    return () => {
      clearInterval(interval);
    };
  }, []);

  function checkService() {
    interval = setInterval(async function () {
      const res = await CheckServiceAPI(service.transaction_code);
      if (res.status_code == 200) {
        // popup
        const popupVal = Object.assign({}, popup);
        popupVal.open = true;
        popupVal.img = "success-icon";
        if (res.result != null) {
          if (service.free_oil) {
            popupVal.content = [
              `You're all set! Your service for vehicle ${service.plate_number} has been approved.`,
            ];
          } else {            
            popupVal.content = [
              `Thank you for your service! You are entitled to the following engine oil for free! ${
                freeOilRangeCopy([...service.current_oils, ...service.selection.current_oils])}.`,
            ];
          }
        } else {
          popupVal.content = [
            `You're all set! Your service for vehicle ${service.plate_number} has been approved.`,
          ];
        }

        popupVal.buttons = [
          {
            type: "primary",
            text: "BACK TO DASHBOARD",
            tap: () => {
              history.push("/loyalty");
            },
          },
        ];
        setPopup(popupVal);
        clearInterval(interval);
      } else if (res.status_code == 403) {
        // popup
        const popupVal = Object.assign({}, popup);
        popupVal.open = true;
        popupVal.img = "danger-icon";
        popupVal.content = [
          "Oh no! Your service submission was not approved. Please check your inbox for details.",
        ];

        popupVal.buttons = [
          {
            type: "primary",
            text: "GO TO INBOX",
            tap: () => {
              history.push("/inbox");
            },
          },
        ];
        setPopup(popupVal);
        clearInterval(interval);
      }
    }, 3500);
  }

  return (
    <div className="service-description-component">
      <div className="container">
        <div className="row">
          <div className="offset-1 offset-md-2 col-10 col-md-8 mb-3 px-4 px-md-0">
            <h2 className="text-center">STEP 4</h2>
          </div>
          <div className="offset-1 offset-md-2 col-10 col-md-8 mb-4 px-4 px-md-0">
            <StepBarComponent active="4"></StepBarComponent>
          </div>
          <div className="offset-1 offset-md-2 col-10 col-md-8 mb-4 px-4 px-md-0 text-center">
            <p className="mb-0">Verify your service with your workshop representative here</p>
          </div>
          <div className="offset-1 offset-md-4 col-10 col-md-4 mb-3 px-4 px-md-0">
            <div className="d-flex service-description">
              <p className="mb-0">Mobile Number:</p>
              <p className="mb-0">
                <b>{userProfile.mobile_number || ""}</b>
              </p>
            </div>
            <div className="d-flex service-description">
              <p className="mb-0">Vehicle:</p>
              <p className="mb-0">
                <b>{service.car_name}</b>
              </p>
            </div>
            <div className="d-flex service-description">
              <p className="mb-0">Mileage:</p>
              <p className="mb-0">
                <b>{(+service.current_mileage).toLocaleString()}km</b>
              </p>
            </div>
            <div className="d-flex service-description">
              <p className="mb-0">Service Date:</p>
              <p className="mb-0">
                <b>{service.service_date}</b>
              </p>
            </div>
            <div className="d-flex service-description">
              <p className="mb-0">Workshop ID:</p>
              <p className="mb-0">
                <b>{service.workshop_id.floating}</b>
              </p>
            </div>
            <div className="d-flex service-description">
              <p className="mb-0">Oil Variant:</p>
              <p className="mb-0">
                <b>{service.oil.float}</b>
              </p>
            </div>
            <div className="d-flex service-description">
              <p className="mb-0">Quantity:</p>
              <p className="mb-0">
                <b>
                  {service.bottle_no}{" "}
                  {service.bottle_no === 1 ? "bottle" : "bottles"}
                </b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
