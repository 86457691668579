import React, { useState, useContext, useEffect } from "react";
import { AddServiceContext } from "../../../../../../provider/add-service-provider";
import { PopupContext } from "../../../../../../provider/popup-provider";
import StepOneComponent from "../../step-1/step-1";
import StepThreeComponent from "../../step-3/step-3";
import "./bottle-quantity.scss";

export default function BottleQuantityComponent(props) {
  const [radio_value, setRadioValue] = useState("");
  const { popup, setPopup, closePopup } = useContext(PopupContext);
  const { service, setService } = useContext(AddServiceContext);

  useEffect(() => {
    setRadioValue(service.bottle_no);
  }, []);

  const radioCustom = (e) => {
    let serviceInfo = Object.assign({}, service);
    serviceInfo.bottle_no = e;
    if (e === 1) {
      setRadioValue(1);
    }
    if (e === 2) {
      setRadioValue(2);
    }
  };

  const submitStepTwoForm = (e) => {
    const one_bottle = document.getElementById("bottle-1");
    const two_bottle = document.getElementById("bottle-2");
    let bottle_quantity = 0;

    if (service.oil.float === "") {
      const popupVal = Object.assign({}, popup);
      popupVal.open = true;
      popupVal.img = "danger-icon";
      popupVal.content = ["Please select the product to proceed."];
      popupVal.buttons = [
        {
          type: "primary",
          text: "Close",
          tap: () => {
            closePopup();
          },
        },
      ];
      setPopup(popupVal);
    }

    if (!one_bottle.checked && !two_bottle.checked) {
      const popupVal = Object.assign({}, popup);
      popupVal.open = true;
      popupVal.img = "danger-icon";
      popupVal.content = ["Please select the quantity to proceed."];
      popupVal.buttons = [
        {
          type: "primary",
          text: "Close",
          tap: () => {
            closePopup();
          },
        },
      ];
      setPopup(popupVal);
    }

    if (one_bottle.checked) {
      bottle_quantity = 1;
    }
    if (two_bottle.checked) {
      bottle_quantity = 2;
    }

    if (service.oil.float !== "" && bottle_quantity > 0) {
      let serviceInfo = Object.assign({}, service);
      serviceInfo.screen = <StepThreeComponent></StepThreeComponent>;
      serviceInfo.bottle_no = bottle_quantity;
      setService(serviceInfo);
    }
  };

  function goBack() {
    let serviceInfo = Object.assign({}, service);
    serviceInfo.screen = <StepOneComponent></StepOneComponent>;
    setService(serviceInfo);
  }

  // Hide 2nd bottle
  let radioVisibility = "hidden";
  if (
    service.cycle_oil_type === 0 ||
    service.cycle_oil_type - service.current_service.floating > 1
  ) {
    radioVisibility = "visible";
  }

  return (
    <section className="bottle-quantity-component pt-4 pt-md-5 pb-5">
      <div className="container">
        <div className="row">
          <div className="offset-1 offset-md-4 col-10 col-md-4 mb-4 px-4 px-md-0 mb-3">
            <div className="d-flex justify-content-around">
              <b>Quantity:</b>
              <div className="input-control">
                <label className="tom-checkbox">
                  <input
                    type="checkbox"
                    id="bottle-1"
                    name="bottle_1"
                    value="1 Bottle"
                    onChange={() => radioCustom(1)}
                    checked={radio_value === 1 ? true : false}
                  />
                  <span className="checkmark"></span>1 Bottle
                </label>
              </div>
              <div
                className="input-control"
                style={{
                  visibility: radioVisibility,
                }}
              >
                <label className="tom-checkbox">
                  <input
                    type="checkbox"
                    id="bottle-2"
                    name="bottle_2"
                    value="2 Bottle"
                    onChange={() => radioCustom(2)}
                    checked={radio_value === 2 ? true : false}
                  />
                  <span className="checkmark"></span>2 Bottles
                </label>
              </div>
            </div>
          </div>
          <div className="offset-1 offset-md-2 col-10 col-md-8 mb-4 px-4 px-md-0 mb-3">
            <button
              className="tom-button primary-button"
              onClick={(e) => submitStepTwoForm(e)}
            >
              Next
            </button>
          </div>
          <div className="offset-1 offset-md-2 col-10 col-md-8 mb-4 px-4 px-md-0">
            <button
              className="tom-button default-button"
              onClick={() => goBack()}
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
