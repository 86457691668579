import React from "react";
import "./login-template.scss";
import NavbarComponent from "../global-component/navbar/navbar";
import NavActiveContextProvider from "../../provider/navbar-provider";
import InboxContextProvider from "../../provider/inbox-provider";
import UserContextProvider from "../../provider/user-provider";
import PopupContextProvider from "../../provider/popup-provider";
import PWAPrompt from "react-ios-pwa-prompt";

const LoginTemplate = (props) => {
  return (
    <div className="login-template">
      <PopupContextProvider>
        <NavActiveContextProvider current_page={props.nav_active}>
          <UserContextProvider>
            <InboxContextProvider>
              <PWAPrompt />
              <NavbarComponent></NavbarComponent>
              <div className="props">{props.children}</div>
            </InboxContextProvider>
          </UserContextProvider>
        </NavActiveContextProvider>
      </PopupContextProvider>
    </div>
  );
}

export default LoginTemplate;