export const togglePassword = (e, password_id) => {
  const type =
    document.getElementById(`${password_id}`).getAttribute("type") ===
    "password"
      ? "text"
      : "password";
  document.getElementById(`${password_id}`).setAttribute("type", type);
  if (type !== "password") {
    e.target.classList.add("icon-eye-hidden");
    e.target.classList.remove("icon-eye");
  } else {
    e.target.classList.remove("icon-eye-hidden");
    e.target.classList.add("icon-eye");
  }
};
