import React, { useEffect, useState } from "react";
import TopSectionScreen from "../../general-screen/top-section/top-section";
import InboxListSection from "./inbox-list/inbox-list";
import "./inbox.scss";
import { readAllApi } from "./read_all-api";

export default function InboxScreen(props) {
  const [init, setInit] = useState(false);
  useEffect(() => {
    if (!init) {
      readAllApi();
    }

    setInit(true);
  }, []);

  return (
    <div className="inbox-screen">
      <TopSectionScreen title="Inbox"></TopSectionScreen>
      <InboxListSection></InboxListSection>
    </div>
  );
}
