import React from "react";
import ContentComponent from "./content-component/content-component";
import TopSectionScreen from "../top-section/top-section";
import "./promotion.scss";

export default function PromotionScreen(props) {
  return (
    <div className="prmotions-screen">
    <TopSectionScreen title="PROMOTIONS"></TopSectionScreen>
    <ContentComponent></ContentComponent>
    </div>
  );
}
