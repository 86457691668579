import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import "./registration-form.scss";
import { Link, useHistory } from "react-router-dom";
import { registrationAPI } from "./registration-api";
import {
  formatDateForm,
  formatDateYear,
} from "../../../../../helpers/moment-helper";
import { validation_message } from "../../../../../data/validation_message";
import { togglePassword } from "../../../../../helpers/toggle-password";
import SpinnerComponent from "../../../../../templates/global-component/spinner-component/spinner-component";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaKey } from "../../../../../config/config";
import { PopupContext } from "../../../../../provider/popup-provider";
import SelectDatepicker from "react-select-datepicker";

export default function RegistrationFormComponent(props) {
  const { popup, setPopup, closePopup } = useContext(PopupContext);
  const history = useHistory();
  const { register, handleSubmit, errors, watch } = useForm();
  const password = useRef();
  password.current = watch("password", "");

  const [dob, setDOB] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const recaptchaRef = useRef();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const onSubmit = async (data) => {
    let popupVal = Object.assign({}, popup);
    setIsLoading(true);
    console.log({ onSubmit: onSubmit });

    if (!isLoading) {
      const resultRegistration = await registrationAPI(
        data.name,
        data.dob,
        data.email,
        `${data.mobile_ext_no}${data.mobile_no}`,
        data.password_confirmation,
        data.password_confirmation,
        data.referrer_code
      );

      if (resultRegistration == 200) {
        history.push({
          pathname: "/register/tac-number",
          state: { register_phone: data.mobile_ext_no + data.mobile_no },
        });
      } else if (resultRegistration == 400) {
        popupVal.open = true;
        popupVal.content = [
          "Syiok! Loyalty Programme website is strictly for end-consumers who are not in any way affiliated with TotalEnergies or participating workshops.",
        ];
        popupVal.img = "danger-icon";
        popupVal.buttons = [
          {
            type: "primary",
            text: "OK",
            tap: () => {
              closePopup();
            },
          },
        ];
        setPopup(popupVal);
        setIsLoading(false);
      } else if (resultRegistration == 409) {
        popupVal.open = true;
        popupVal.content = ["Mobile number has been registered"];
        popupVal.img = "danger-icon";
        popupVal.buttons = [
          {
            type: "primary",
            text: "OK",
            tap: () => {
              closePopup();
            },
          },
        ];
        setPopup(popupVal);
        setIsLoading(false);
      } else {
        if (resultRegistration == 500) {
          console.log('popap: ', resultRegistration);
          popupVal.open = true;
          popupVal.content = [resultRegistration];
          popupVal.img = "danger-icon";
          popupVal.buttons = [
            {
              type: "primary",
              text: "OK",
              tap: () => {
                closePopup();
              },
            },
          ];
          setPopup(popupVal);
          setIsLoading(false);
        } else if (resultRegistration == 'internet') {
          let popupVal = Object.assign({}, popup);
          popupVal.open = true;
          popupVal.content = [
            "Oh no! Seems like you're not connected to the internet. Make sure your WiFi or mobile data is turned on.",
          ];
          popupVal.img = "";
          popupVal.buttons = [
            {
              type: "primary",
              text: "OK",
              tap: () => {
                closePopup();
              },
            },
          ];
          setPopup(popupVal);
          setIsLoading(false);
        }
      }
    }
  };

  const onChangeRecaptcha = () => {
    const recaptchaValue = recaptchaRef.current.getValue();
    document.getElementById("recaptcha").checked = true;
  };

  const onDateChange = useCallback((date) => {
    if (date !== null) {
      setDOB(date);
    }
  }, []);

  return (
    <section className="registration-form">
      <div className="container">
        <div className="row mb-4">
          <div className="col-6">
            <h2 className="title mb-0">Register</h2>
          </div>
          <div className="col-6 text-right pt-2">
            <p className="text-responsive text-sm mb-0">
              Already a member?{" "}
              <Link to="/login" className="text-primary">
                Login here
              </Link>
            </p>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-md-2 mb-2">
              <div className="input-control">
                <input
                  type="text"
                  ref={register({
                    required: {
                      value: true,
                      message: `*${validation_message.register.full_name.require.message}`,
                    },
                    minLength: {
                      value: 5,
                      message: `*${validation_message.register.full_name.minLength.message}`,
                    },
                    maxLength: {
                      value: 60,
                      message: `*${validation_message.register.full_name.maxLength.message}`,
                    },
                    pattern: {
                      value: /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-/, ])*$/,
                      message: `*${validation_message.register.full_name.pattern.message}`,
                    },
                  })}
                  name="name"
                  className="tom-input"
                  placeholder="Full Name"
                  autoComplete="off"
                  maxLength="60"
                />
                <small className="error">
                  <ErrorMessage errors={errors} name="name" />
                </small>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-md-2">
              <div className="input-control">
                <input
                  type="text"
                  ref={register({
                    required: {
                      value: true,
                      message: `*${validation_message.register.date_birth.require.message}`,
                    },
                    pattern: {
                      value: /(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))/,
                      message: `*${validation_message.register.date_birth.pattern.message}`,
                    },
                  })}
                  style={{ display: "none" }}
                  name="dob"
                  defaultValue={dob ? formatDateForm(dob) : ""}
                />
                <div className="d-md-inline-flex pt-2 pt-md-0">
                  <span className="mt-md-2 mr-md-4">Date of Birth:</span>
                  <SelectDatepicker
                    value={dob}
                    onDateChange={onDateChange}
                    minDate={new Date(1930, 1, 1)}
                    maxDate={
                      new Date(+formatDateYear(new Date()) - 18, +11, 31)
                    }
                    showLabels={false}
                    showErrors={false}
                  />
                </div>
                {!dob ? (
                  <small className="error">
                    <ErrorMessage errors={errors} name="dob" />
                  </small>
                 ) : (
                  ""
                )} 
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-md-2">
              <div className="mobile-no-input">
                <div className="input-control">
                  <select
                    ref={register}
                    name="mobile_ext_no"
                    className="tom-input"
                    id="mobile-ext-no"
                    onChange={() => {
                      document.getElementById("mobile-no").value = "";
                    }}
                  >
                    <option value="60">MY: +60</option>
                    <option value="65">SG: +65</option>
                    <option value="65">BN: +673</option>
                    <option value="65">TH: +66</option>
                  </select>
                  <input
                    type="number"
                    ref={register({
                      required: {
                        value: true,
                        message: `*${validation_message.register.mobile_no.require.message}`,
                      },
                      minLength: {
                        value: 7,
                        message: `*${validation_message.register.mobile_no.minLength.message}`,
                      },
                      maxLength: {
                        value: 13,
                        message: `*${validation_message.register.mobile_no.maxLength.message}`,
                      },
                      pattern: {
                        value: /^[0-9]{7,13}$/,
                        message: `*${validation_message.register.mobile_no.pattern.message}`,
                      },
                    })}
                    id="mobile-no"
                    name="mobile_no"
                    inputMode="decimal"
                    className="tom-input"
                    placeholder="Mobile No."
                    onChange={(e) => {
                      if (
                        document.getElementById("mobile-ext-no").value == 60 &&
                        e.target.value.charAt(0) === "0"
                      ) {
                        return (e.target.value = e.target.value.slice(1));
                      }
                      if (e.target.value.toString().length > 13) {
                        return (e.target.value = e.target.value.substr(0, 13));
                      }
                    }}
                  />
                  <small className="error">
                    <ErrorMessage errors={errors} name="mobile_no" />
                  </small>
                </div>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-md-2">
              <div className="input-control">
                <input
                  type="text"
                  ref={register({
                    required: {
                      value: true,
                      message: `*${validation_message.register.email.require.message}`,
                    },
                    pattern: {
                      value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: `*${validation_message.register.email.pattern.message}`,
                    },
                  })}
                  name="email"
                  className="tom-input"
                  placeholder="Email"
                  autoComplete="off"
                />
                <small className="error">
                  <ErrorMessage errors={errors} name="email" />
                </small>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-md-2">
              <div className="input-control">
                <input
                  type="password"
                  ref={register({
                    required: {
                      value: true,
                      message: `*${validation_message.register.password.require.message}`,
                    },
                    pattern: {
                      value: /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[!@#$%^&*(),.?":{}|<>_~`+-]).{8,}$/,
                      message: `*${validation_message.register.password.pattern.message}`,
                    },
                  })}
                  id="password"
                  name="password"
                  className="tom-input mb-2"
                  placeholder="Create Password"
                  autoComplete="off"
                />
                <span
                  className="icon-eye"
                  onClick={(e) => {
                    togglePassword(e, "password");
                  }}
                ></span>
                <p>
                  <i>
                    Please ensure your password includes min. 8 characters (min.
                    1 uppercase character, 1 lowercase character, 1 number and 1
                    special character).
                  </i>
                </p>
                <small className="error">
                  <ErrorMessage errors={errors} name="password" />
                </small>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-md-2">
              <div className="input-control">
                <input
                  type="password"
                  ref={register({
                    required: {
                      value: true,
                      message: `*${validation_message.register.confirm_password.require.message}`,
                    },
                    validate: (value) =>
                      value === password.current ||
                      `*${validation_message.register.confirm_password.pattern.message}`,
                  })}
                  id="password_confirmation"
                  name="password_confirmation"
                  className="tom-input"
                  placeholder="Confirm Password"
                  autoComplete="off"
                />
                <span
                  className="icon-eye"
                  onClick={(e) => {
                    togglePassword(e, "password_confirmation");
                  }}
                ></span>
                <small className="error">
                  <ErrorMessage errors={errors} name="password_confirmation" />
                </small>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-2">
              <div className="input-control">
                <input
                  type="text"
                  className="tom-input"
                  placeholder="Referal Code"
                  autoComplete="off"
                  defaultValue={urlParams.get('ref')}
                  name="referrer_code"
                  ref={register}
                />
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8">
              <div className="input-control">
                <label className="tom-checkbox">
                  <input
                    type="checkbox"
                    ref={register({
                      required: {
                        value: true,
                        message: `*${validation_message.register.tnc.require.message}`,
                      },
                    })}
                    name="tnc_agreement"
                  />
                  <span className="checkmark"></span>I agree to the{" "}
                  <a
                    href={
                      process.env.PUBLIC_URL +
                      "/assets/pdf/Quartz_Syiok!_Loyalty_Programme_Privacy Policy_26112021.pdf"
                    }
                    target="_blank"
                    className="text-primary"
                  >
                    Personal Data Protection Act
                  </a>{" "}
                  &amp;{" "}
                  <a
                    href={
                      process.env.PUBLIC_URL +
                      "/assets/pdf/Quartz_Syiok!_Loyalty_Programme_T&C_02122021.pdf"
                    }
                    target="_blank"
                    className="text-primary"
                  >
                    Terms &amp; Conditions
                  </a>
                  <small className="error pt-2">
                    <ErrorMessage errors={errors} name="tnc_agreement" />
                  </small>
                </label>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-1">
              <div className="input-control">
                <label className="tom-checkbox">
                  <input
                    type="checkbox"
                    ref={register({})}
                    name="future_update"
                  />
                  <span className="checkmark"></span>I agree to receive future
                  updates from TotalEnergies{" "}
                  <small className="error d-inline-block">
                    <ErrorMessage errors={errors} name="future_update" />
                  </small>
                </label>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-3">
              <div className="input-control">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={recaptchaKey.sitekey}
                  onChange={onChangeRecaptcha}
                />
                <input
                  type="checkbox"
                  ref={register({
                    required: {
                      value: true,
                      message: `*Validation error`,
                    },
                  })}
                  id="recaptcha"
                  name="recaptcha"
                  className="d-none"
                />
                <small className="error mt-1">
                    <ErrorMessage errors={errors} name="recaptcha" />
                </small>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8">
              <button
                className="tom-button primary-button"
                disabled={isLoading}
              >
                Register
                {isLoading ? (
                  <SpinnerComponent
                    fontColor={"text-white"}
                    dimensions={{ mobile: "14px", desktop: "1rem" }}
                  ></SpinnerComponent>
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}
