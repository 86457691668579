import React from "react";
import WelcomeComponent from "../../login/welcome/welcome";
import MGMForm from "./mgm-form/mgm-form";
import "./mgm.scss";

export default function MemberGetMemberScreen(props) {
    return (
        <div className="member-get-member-screen">
            <WelcomeComponent></WelcomeComponent>
            <MGMForm></MGMForm>
        </div>
    );
}
