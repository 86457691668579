import React from "react";
import { Route } from "react-router-dom";
import SeoComponent from "./share-component/seo-component";

export default function AppRoute({
  component: Component,
  layout: Layout,
  nav_active: nav_active,
  ...res
}) {
  return (
    <Route
      {...res}
      render={(props) => {
        return (
          <Layout {...props} nav_active={nav_active}>
            <SeoComponent></SeoComponent>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
}
