import React from "react";
import WorkshopLocatorContextProvider from "./map-context";
import TopSectionScreen from "../top-section/top-section";
import MapSearch from "./map-search/map-search";
import MapSection from "./map-section/map-section";
import "./workshop-locator.scss";

export default function WorkshopLocatorScreen(props) {
  return (
    <div className="workshop-locator-screen">
      <WorkshopLocatorContextProvider >
        <TopSectionScreen title="Workshop Locator"></TopSectionScreen>
        <MapSearch></MapSearch>
        <MapSection></MapSection>
      </WorkshopLocatorContextProvider>
    </div>
  );
}
