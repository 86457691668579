import React, { Fragment, useEffect, useContext, useState } from "react";
import { WorkshopLocatorContext } from "../map-context";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import L, { Rectangle, Tooltip } from "leaflet";
import "./map-section.scss";

const geolocation = require("geolocation");

export default function MapSection(props) {
  const { locator, setLocator, marker_list } = useContext(WorkshopLocatorContext);
  const [ initial, setInitial ] = useState(false);
  const [ zoom_level, setZoomLevel ] = useState(14);

  useEffect(() => {
    setZoomLevel(18);
    if (!initial) {
      initiatePosition();
      setInitial(true)
      setZoomLevel(14);
    }

  }, [locator]);

  const initiatePosition = () => {
    geolocation.getCurrentPosition((err, position) => {
      let locatorInfo = Object.assign({}, locator);
      locatorInfo.current_position.lat = position.coords.latitude;
      locatorInfo.current_position.log = position.coords.longitude;
      locatorInfo.center_position.lat = position.coords.latitude;
      locatorInfo.center_position.log = position.coords.longitude;
      setLocator(locatorInfo);
    });
  };

  const customMarker = new L.icon({
    iconUrl: "/assets/images/workshop-pin.png",
    iconSize: [68, 72],
    iconAnchor: [26, 72],
  });

  const customCurrentPositionMarker = new L.icon({
    iconUrl: "/assets/images/location-marker.png",
    iconSize: [50, 72],
    iconAnchor: [26, 72],
  });

  const MyPopupMarker = ({ latitude, longitude, workshop_name, workshop_address, mobile_number }) => (
    <Marker position={[latitude, longitude]} icon={customMarker}>
      <Popup>
        <div className="row">
          <div className="col-12 pl-4">
            <p>
              <b style={{ fontSize: "125%" }}>{workshop_name}</b> <br />
              {workshop_address}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-6 pr-1">
            <button className="tom-button primary-button" onClick={() => {
              window.open(`https://maps.google.com/?q=${latitude},${longitude}&z=100`);
            }}>
              Get Directions
            </button>
          </div>
          <div className="col-6 pl-1">
            <button className="tom-button primary-button" onClick={() => {
                  window.open(`tel:${mobile_number}`);
                }}>Call</button>
          </div>
        </div>
      </Popup>
    </Marker>
  );

  const MyMarkersList = ({ markers }) => {
    const items = markers.map(({ key, ...props }) => (
      <MyPopupMarker key={key} {...props} />
    ));
    return <Fragment>{items}</Fragment>;
  };

  const onMove = (event) => {
    event.target.getCenter();
  };

  return (
    <section className="maps-section">
      <div className="container">
        <div className="row">
          <div className="col-12 px-0">
            <Map
              center={[
                locator.center_position.lat,
                locator.center_position.log,
              ]}
              zoom={zoom_level}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution=""
              />
              <MyMarkersList markers={marker_list} />
              <Fragment>
                <Marker
                  position={[
                    locator.current_position.lat,
                    locator.current_position.log,
                  ]}
                  icon={customCurrentPositionMarker}
                ></Marker>
              </Fragment>

            </Map>
          </div>
        </div>
      </div>
    </section>
  );
}
