import React from "react";
import TopSectionScreen from "../top-section/top-section";
import ContentSection from "./content-section/content-section";
import "./full-syntetic.scss";

export default function FullSynteticScreen(props) {
    return (
        <div className="full-syntetic-screen">
            <TopSectionScreen title="Products"></TopSectionScreen>
            <ContentSection></ContentSection>
        </div>
    );
}
