import React from "react";
import "./popup-component.scss";

export default function PopupComponent(props) {
  const closePopUp = () => {
    props.setPopUpStatus("close");    
  };

  return (
    <div
      className={`popup-component + ${props.popup_status.toggle === "open" ? " active": "" }`}
      id="verify-popup"
    >
      <div className="popup-verification-status" id="wrong-verification">
            <img className="mb-3" src={`${props.pop_up_data.img}`} />
            <p>
              {props.pop_up_data.content != null ?  
              props.pop_up_data.content.map((x) => {
                return <div>{x}</div>
              })
              : ""}
            </p>
            <button
              className="tom-button primary-button"
              onClick={() => closePopUp()}
            >
              {props.pop_up_data.button_name}
            </button>
          </div>
    </div>
  );
}
