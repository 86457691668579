import React from "react";
import PopupContextProvider from "../../../../../provider/popup-provider";
import StepOneFormComponent from "./step-1-form/step-1-form";
import "./step-1.scss";

export default function StepOneComponent(props) {
  return (
    <PopupContextProvider>
      <StepOneFormComponent></StepOneFormComponent>
    </PopupContextProvider>
  );
}
