import React from "react";
import "./top-section.scss";

export default function TopSectionScreen(props) {
  return (
    <section className="top-section-component">
      <h1>{props.title}</h1>
    </section>
  );
}
