import React, { useState, useEffect, useRef } from "react";
import WelcomeComponent from "../../welcome/welcome";
import PopupComponent from "../../../../share-component/popup-component/popup-component";
import RegistrationFormComponent from "./registration-form/registration-form";
import { manipulate_data } from "../../../../helpers/popup_tom";
import "./email-registeration-screen.scss";
import SocialRegistrationFormComponent from "./registration-form/social-registration-form";
import PopupContextProvider from "../../../../provider/popup-provider";

export default function EmailRegisterationScreen(props) {
  const [popup_status, setPopUpStatus] = useState({
    toggle: "",
  });
  const [pop_up_data, setPopUpData] = useState({});
  const [isSocialLogin, setIsSocialLogin] = useState(false);
  const [authData, setAuthData] = useState({})

  const popUp = (name, status, toggle_status) => {
    const data = manipulate_data(name, status);
    setPopUpStatus({ toggle: toggle_status });
    setPopUpData(data);
  };

  useEffect(() => {
    if (typeof props.location.state != "undefined") {
      setIsSocialLogin(props.location.state.is_social);
      setAuthData(props.location.state.data)
    }
  }, []);


  let registrationForm;

  if (isSocialLogin) {
    registrationForm = <SocialRegistrationFormComponent auth={authData} popUp={popUp}></SocialRegistrationFormComponent>
  }
  else {
    registrationForm = <RegistrationFormComponent popUp={popUp}></RegistrationFormComponent>
  }

  return (
    <PopupContextProvider>
      <div className="email-registeration-screen">
        <PopupComponent
          setPopUpStatus={setPopUpStatus}
          popup_status={popup_status}
          pop_up_data={pop_up_data}
        ></PopupComponent>
        <WelcomeComponent></WelcomeComponent>
        {registrationForm}
      </div>
    </PopupContextProvider>
  );
}
