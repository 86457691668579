import Axios from "axios";
import Cookies from "js-cookie";
import { apiURL } from "../../../config/config";

export const readAllApi = async () => {
  try {
    const response = await Axios.put(
      `${apiURL}/api/notification/read_all`,
      {},
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log({ error: error });
  }
};
