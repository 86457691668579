import React, { Fragment, useContext } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./top-banner.scss";
import { Link, useHistory } from "react-router-dom";
import { UserContext } from "../../../../provider/user-provider";
import { LoyaltyContext } from "../../../../provider/loyalty-provider";
import { formatDate } from "../../../../helpers/moment-helper";

export default function TopBannerComponent(props) {
  const history = useHistory();
  const { userProfile } = useContext(UserContext);
  const { nextService } = useContext(LoyaltyContext);

  return (
    <section className="top-banner-section">
      <div className="user-info-section">
        <div className="container">
          <div className="row">
            {userProfile.fullname ?
              <div className="col-6 col-md-3">
                <p className="text-responsive text-md">
                  Hello,{" "}
                  <b>{userProfile.fullname}</b>
                </p>
              </div> : <><div className="col-6 col-md-3 d-none d-md-block">
                <p className="text-responsive text-md">
                  Hello, <b>Guest</b>
                </p>
              </div>
                <div className="col-12 d-md-none"><p>Hello <b>Guest</b>, are you a Quartz Syiok! Loyalty Programme member?</p> <hr className="m-0" /></div></>
            }
            <div className="d-none d-md-block col-md-6 text-center">
              {userProfile.fullname !== null ? (
                <p>
                  {nextService?.next_service_date
                    ? "Your next service date: " +
                    formatDate(nextService.next_service_date)
                    : ""}
                </p>
              ) : (
                <p>
                  Are you a Quartz Syiok! Loyalty Programme member?
                </p>
              )}
            </div>
            {userProfile.fullname !== null ? (
              <div className="col-6 col-md-3 pl-md-0 text-right">
                <Fragment>
                  <p>
                  <Link
                    className="d-none d-md-block link"
                    to={"/loyalty"}
                    className="text-primary d-none d-md-block"
                  >
                    Service now &#8594;
                  </Link>
                  <Link
                    className="d-md-none link"
                    to={"/loyalty"}
                    className="text-responsive text-md line-height-1 text-primary d-md-none"
                  >

                    {nextService?.next_service_date
                      ? "Your next service date: " +
                      formatDate(nextService.next_service_date)
                      : "Service now"}

                    &#8594;
                  </Link>
                  </p>
                </Fragment>
              </div>
            ) : (
              <div className="col-6 col-md-3 pl-md-0 text-md-right">
                <p>
                  <Link className="d-md-none text-primary" to={"/login"}>
                    Log in
                  </Link>
                  <span className="d-md-none">
                    &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <Link to={"/register"} className="text-primary">
                    Sign up &#8594;
                  </Link>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <Carousel
        showDots
        sliderClassName=""
        // slidesToSlide={1}
        // swipeable
        additionalTransfrom={0}
        arrows={false}
        centerMode={false}
        className=""
        dotListClassName=""
        // draggable
        focusOnSelect={false}
        // infinite
        itemClassName=""
        keyBoardControl
        minimumTouchDrag={10}
        renderButtonGroupOutside={false}
        renderDotsOutside
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 1,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 1,
          },
        }}
      >
        <div className="carousel-item-about-us">
          <img
            src="/assets/images/home/TE-BANNER-DESKTOP-1.jpg"
            width="100%"
            alt="SYIOK! Loyalty Programme"
            className="d-none d-md-block"
          />
          <img
            src="/assets/images/home/TE-BANNER-MOBILE-1.jpg"
            width="100%"
            alt="SYIOK! Loyalty Programme"
            className="d-md-none"
          />
        </div>
        <div className="carousel-item-about-us">
          <img
            src="/assets/images/home/TE-BANNER-DESKTOP-2.png"
            width="100%"
            alt="SYIOK! Loyalty Programme"
            className="d-none d-md-block"
          />
          <img
            src="/assets/images/home/TE-BANNER-MOBILE-2.png"
            width="100%"
            alt="SYIOK! Loyalty Programme"
            className="d-md-none"
          />
        </div>
        <div className="carousel-item-about-us">
          <img
            src="/assets/images/home/TE-BANNER-DESKTOP-3.png"
            width="100%"
            alt="SYIOK! Loyalty Programme"
            className="d-none d-md-block"
          />
          <img
            src="/assets/images/home/TE-BANNER-MOBILE-3.png"
            width="100%"
            alt="SYIOK! Loyalty Programme"
            className="d-md-none"
          />
        </div>
      </Carousel>

      <div className="container">
        <div className="card pt-3">
          <div className="row">
            <div className="col-8 col-md-8 pl-4 pl-md-5">
              <h2 className="text-responsive text-lg mb-0">
                One Click Away to Great Rewards
              </h2>
              <p className="text-responsive text-sm mb-3">
                Become a member today to enjoy rewards out of this world!
              </p>
            </div>
            <div className="col-button col-4 col-md-4 pb-3 pl-0 pl-md-0 pr-4 pr-md-5 mt-auto">
              <button
                className="tom-button primary-button arrow"
                onClick={() => history.push("/register")}
              >
                FIND OUT MORE
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
