import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import "./bottom-navigation.scss";
import { NavActiveContext } from "../../../provider/navbar-provider";

export default function BottomNavigationComponent() {
  const { nav_active } = useContext(NavActiveContext);
  const history = useHistory();

  return (
    <div className="bottom-navigation-component">
      <div className="navigation-list">
        <div
          className={`nav-item pr-3 ${
            nav_active.active === "home" ? "active" : ""
          }`}
          onClick={(e) => history.replace("/")}
        >
          <div className="item">
            <p className="mb-0">
              <i className="icon-home-run"></i> <br />
              Home
            </p>
          </div>
        </div>
        {Cookies.get("access_token") !== undefined ? (
          <div
            className={`nav-item login ${
              nav_active.active === "loyalty" ? "active" : ""
            }`}
            onClick={(e) => history.replace("/loyalty")}
          >
            <i className="icon-user-copy"></i>
            <p className="mb-0">Dashboard</p>
          </div>
        ) : (
          <div
            className={`nav-item login`}
            onClick={(e) => history.replace("/login")}
          >
            <i className="icon-user-copy"></i>
            <p className="mb-0">Login</p>
          </div>
        )}
        <div
          className={`nav-item pl-3 ${
            nav_active.active === "workshops_locator" ? "active" : ""
          }`}
          onClick={(e) => history.replace("/workshops-locator")}
        >
          <div className="item">
            <p className="mb-0">
              <i className="icon-placeholder"></i> <br />
              Workshops
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
