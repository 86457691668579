import React, { useContext, useEffect } from "react";
import { AddServiceContext } from "../../../../../provider/add-service-provider";
import PopupContextProvider from "../../../../../provider/popup-provider";
import StepThreeFormComponent from "./step-3-form/step-3-form";
import "./step-3.scss";

export default function StepThreeComponent(props) {
  const { service, setService } = useContext(AddServiceContext);

  useEffect(() => {
    let serviceInfo = Object.assign({}, service);
    serviceInfo.selection.current_oils = Array(service.bottle_no).fill(serviceInfo.oil.float);
    setService(serviceInfo);
  }, []);

  return (
    <PopupContextProvider>
      <StepThreeFormComponent></StepThreeFormComponent>
    </PopupContextProvider>
  );
}
