import React, { Fragment, useContext, useEffect } from "react";
import Carousel from "react-multi-carousel";
import { Link, useHistory } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import "./registered-vehicle.scss";
import { LoyaltyContext } from "../../../../../provider/loyalty-provider";
import ServiceStatus from "../../../../../share-component/service-status";
import { formatDate } from "../../../../../helpers/moment-helper";

export default function RegisteredVehicleComponent(props) {
  const { loadingIndicator, vehicles } = useContext(LoyaltyContext);
  const history = useHistory();

  return (
    <section className="registered-vehicle-component mb-4">
      <div className="container">
        <div className="row mb-4">
          <div className="col-12 pl-md-5">
            <h2 className="title mb-0">My Vehicles</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12 pl-md-4 pr-0">
            {loadingIndicator ? (
              <center>
                <div className="spinner-border text-danger mt-5" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </center>
            ) : (
              <Fragment>
                {vehicles === null || vehicles.length <= 0 ? (
                  <div className="text-center py-4">
                    <img
                      src="/assets/images/no-cars_03.png"
                      alt="Add your vehicle now to kick-start your Syiok! Loyalty Programme"
                      width="50%"
                    />
                    <p className="text-grey">
                      No vehicles in the list. <br /> Add your vehicle now to
                      kick-start <br /> your Syiok! Loyalty Programme
                    </p>
                  </div>
                ) : (
                  <Fragment>
                    <Carousel
                      showDots={false}
                      sliderClass=""
                      slidesToSlide={0}
                      swipeable={true}
                      draggable={false}
                      additionalTransfrom={0}
                      arrows={false}
                      centerMode={false}
                      className=""
                      dotListClass=""
                      draggable={false}
                      focusOnSelect={false}
                      itemClass=""
                      keyBoardControl={false}
                      minimumTouchDrag={100}
                      renderButtonGroupOutside={false}
                      renderDotsOutside={false}
                      responsive={{
                        desktop: {
                          breakpoint: {
                            max: 3000,
                            min: 1024,
                          },
                          items: 2.3,
                        },
                        mobile: {
                          breakpoint: {
                            max: 464,
                            min: 0,
                          },
                          items: 1.1,
                        },
                        tablet: {
                          breakpoint: {
                            max: 1024,
                            min: 464,
                          },
                          items: 1.7,
                        },
                      }}
                    >
                      {vehicles.map((value, index) => {
                        return (
                          <div
                            key={index}
                            className="item h-100 pt-2 px-2"
                          >
                            <div
                              className="service-box h-100"
                              onClick={() =>
                                history.push({
                                  pathname: "/loyalty/service-information",
                                  state: {
                                    vehicle: value,
                                  },
                                })
                              }
                            >
                              <div className="car-info-section">
                                <div className="car-info">
                                  <div>
                                    <p className="mb-0">
                                      <b>
                                        {value.model_brand} {value.model_name}
                                      </b>{" "}
                                      <br /> {value.plate_number}
                                    </p>
                                  </div>
                                  <div>
                                    <img
                                      src="/assets/images/add-vehicles-fit.png"
                                      width="100%"
                                      alt="Vehicle"
                                    />
                                  </div>
                                </div>
                                <p className="mb-0">
                                  <b>Workshop ID:</b>{" "}
                                  {value.workshop_id
                                    ? value.workshop_id
                                    : "N/A"}
                                </p>
                                <div className="block-ellipsis">
                                  <b>Workshop name:</b>{" "}
                                  {value.workshop_name
                                    ? value.workshop_name
                                    : "N/A"}
                                </div>
                              </div>
                              <div className="service-info-section">
                                <div className="row">
                                  <div className="col-12">
                                    <p className="mb-1">
                                      Oil variant: <br />
                                      <b>
                                        {value.oil != "" ? value.oil : "N/A"}
                                      </b>
                                    </p>
                                    <p className="mb-1">
                                      Next service mileage: <br />
                                      <b>
                                        {value.next_mileage
                                          ? value.next_mileage.toLocaleString()
                                          : "N/A"}
                                      </b>
                                    </p>
                                    <p className="mb-0">
                                      Next service date: <br />
                                      <b>
                                        {value.next_mileage
                                          ? formatDate(value.next_service_date)
                                          : "N/A"}
                                      </b>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="service-status-section row py-2 px-3 px-md-0">
                                <ServiceStatus
                                  isClickable={false}
                                  vehicle={value}
                                ></ServiceStatus>
                              </div>
                            </div>

                            <div className="row service-description-button">
                              <div className="col-6 pl-0 pr-1">
                                <button
                                  className="tom-button default-button"
                                  onClick={() =>
                                    history.push({
                                      pathname: "/loyalty/service-information",
                                      state: {
                                        vehicle: value,
                                        cycle_guid: value.services.filter(x => x.status <= 1)?.length ? value.cycle_guid : '',
                                        car_name: `${value.model_brand} ${value.model_name} ${value.plate_number}`,
                                        plate_number: value.plate_number,
                                        workshop_id: value.workshop_id,
                                        cycle_oil_type: value.cycle_oil_type,
                                        oil_code: value.oil_code,
                                        free_oil: value.free_oil,
                                        current_service: value.services.filter(x => x.status <= 1)?.length,
                                        last_service_date:
                                          value.services.filter(x => x.status <= 1)?.length > 0
                                            ? value.services[0].created_date
                                            : null,
                                      },
                                    })
                                  }
                                >
                                  View More
                                </button>
                              </div>
                              <div className="col-6 px-1 pr-0">
                                <button
                                  className="tom-button primary-button"
                                  onClick={() =>
                                    history.push({
                                      pathname: "/loyalty/add-service",
                                      state: {
                                        guid: value.guid,
                                        cycle_guid: value.services.filter(x => x.status <= 1)?.length > 0 ?
                                          value.cycle_guid : '',
                                        car_name: `${value.model_brand} ${value.model_name} ${value.plate_number}`,
                                        plate_number: value.plate_number,
                                        workshop_id: value.workshop_id,
                                        cycle_oil_type: value.services.filter(x => x.status <= 1)?.length > 0
                                          ? value.cycle_oil_type : 0,
                                        oil_code: value.oil_code,
                                        free_oil: value.free_oil,
                                        current_service: value.services.filter(x => x.status <= 1)?.length,
                                        last_service_date:
                                          value.services.filter(x => x.status <= 1)?.length > 0
                                            ? value.services[0].created_date
                                            : null,
                                        current_oils: value.services.filter(x => x.status <= 1).map(x => x.OilCode)
                                      },
                                    })
                                  }
                                >
                                  Add Service
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Carousel>
                    <p>
                      Next service mileages and dates are only recommended
                      service intervals. Your service needs may differ according
                      to usage.
                    </p>
                  </Fragment>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
