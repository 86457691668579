import React from "react";
import { Link, useHistory } from "react-router-dom";
import "./user-option-section.scss";

export default function UserOptionSectionComponent(props) {
  const history = useHistory();
  return (
    <section className="user-option-section-component">
      <div className="container">
        <div className="row px-md-4 text-center text-md-left">
          <div className="col-4 px-2">
            <div className="option-box" onClick={() => history.push("/loyalty/add-new-vehicle")}>
              <img src="/assets/images/add-car.png" alt="Add a vehicle" width="100%" />
              <p className="text-responsive text-xs mb-0">Add a <br className="d-none d-md-block" /> vehicle</p>
            </div>
          </div>
          <div className="col-4 px-2">
            <div className="option-box" onClick={() => history.push("/workshops-locator")}>
              <img src="/assets/images/location.png" alt="Workshop Locator" width="100%" />
              <p className="text-responsive text-xs mb-0">Workshop <br className="d-none d-md-block" /> locator</p>
            </div>
          </div>
          <div className="col-4 px-2">
            <div className="option-box" onClick={() => history.push("/refer-and-earn")}>
              <img src="/assets/images/refer-earn.png" alt="Refer & earn" width="100%" />
              <p className="text-responsive text-xs mb-0">Refer <br className="d-none d-md-block" /> &amp; earn</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
