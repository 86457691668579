import React from "react";
import AddVehicleFormScreen from "./add-vehicle-form/add-vehicle-form";
import TopSectionScreen from "../../../general-screen/top-section/top-section";
import PopupContextProvider from "../../../../provider/popup-provider";
import "./add-vehicle-screen.scss";

export default function AddVehicleScreen(props) {
  return (
    <div className="add-vehicle-screen">
      <PopupContextProvider>
        <TopSectionScreen title="Add Vehicle"></TopSectionScreen>
        <AddVehicleFormScreen></AddVehicleFormScreen>
      </PopupContextProvider>
    </div>
  );
}
