import React from "react";
import { useHistory } from "react-router";
import "./content-section.scss";

export default function ContentSection() {
  const history = useHistory();

  return (
    <section className="content-section">
      <div className="container">
        <div className="row mt-5 mb-5 text-center">
          <div className="offset-3 offset-md-5 col-6 col-md-2 mb-4">
            <img src="/assets/images/pop-up/success-icon.jpg" width="100%" />
          </div>
          <div className="offset-0 offset-md-3 col-12 col-md-6 mb-4">
            <h2>UPDATE SUCCESSFUL</h2>
          </div>
          <div className="offset-1 offset-md-3 col-10 col-md-6 mb-4 text-center text-md-right">
            <button
              className="tom-button primary-button w-100"
              onClick={(e) => history.push("/loyalty")}
            >
              Back To Dashboard
              </button>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12">
            <p className="mb-0 title">
              <b>Check out our range of products</b>
            </p>
          </div>
        </div>
        <div className="row mt-5 mb-5 justify-content-center">
          <div className="col-12 col-md-7">
            <div className="card card-main" onClick={(e) => history.push("/buy3get1")}>
              <div className="row">
                <div className="col-8 pl-md-5">
                  <div className="d-flex flex-column justify-content-center h-100">
                    <p className="product-category">
                      <b>Buy 3 Get 1 Free</b> <br />
                      <span>QUARTZ INEO Range <br />
                      QUARTZ 9000 Range</span>
                    </p>
                  </div>
                </div>
                <div className="col-4">
                  <div className="product-brand">
                    <img
                      src="/assets/images/QUARTZINEOHTC5W30.png"
                      alt="Buy 3 get 1 free"
                      width="100%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5 mb-5 justify-content-center">
          <div className="col-12 col-md-7">
            <div className="card card-main" onClick={(e) => history.push("/buy4get1")}>
              <div className="row">
                <div className="col-8 pl-md-5">
                  <div className="d-flex flex-column justify-content-center h-100">
                    <p className="product-category">
                      <b>Buy 4 Get 1 Free</b> <br />
                      <span>QUARTZ 8000 Range <br />
                      QUARTZ 7000 Range</span>
                    </p>
                  </div>
                </div>
                <div className="col-4">
                  <div className="product-brand">
                    <img
                      src="/assets/images/QUARTZ7000CI410W30.png"
                      alt="Buy 4 get 1 free"
                      width="100%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
