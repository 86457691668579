import React, { createContext, useEffect, useState } from "react";
import Axios from "axios";
import { apiURL } from "../../../config/config";

export const WorkshopLocatorContext = createContext();

const WorkshopLocatorContextProvider = (props) => {
  const [locator, setLocator] = useState({
    current_position: { lat: 3.1384965, log: 101.5468994 },
    center_position: { lat: 3.1384965, log: 101.5468994 },
  });

  const [marker_list_api, setMarkerListApi] = useState([]);
  const [marker_list, setMarkerList] = useState([]);

  const [init, setInit] = useState(true);

  useEffect(() => {
    if (init) {
      const getMarkerList = async () => {
        try {
          const response = await Axios.get(`${apiURL}/api/workshop/active`);

          let _marker_list = response.data.result.filter(
            (result) => result.latitude.length !== 0 && result.longitude.length !== 0 && result.workshop_id != "8888" && result.workshop_id != "9999"
          );

          setMarkerListApi(_marker_list);
          setMarkerList(_marker_list);
        } catch (error) {
          console.log(error);
        }
      };

      getMarkerList();
      setInit(false);
    }
  }, []);

  return (
    <WorkshopLocatorContext.Provider
      value={{ locator, setLocator, marker_list_api, setMarkerListApi, marker_list, setMarkerList }}
    >
      {props.children}
    </WorkshopLocatorContext.Provider>
  );
};

export default WorkshopLocatorContextProvider;
