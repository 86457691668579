import React from "react";
import "./step-bar-component.scss";

export default function StepBarComponent(props) {
  return <div className="step-bar-component d-flex justify-content-between">
    <div className="line"></div>
    <div className="d-flex dots justify-content-between w-100">
      <div className={`dot ${props.active === "1" ? "active" : ""}`} id="dot-1"></div>
      <div className={`dot ${props.active === "2" ? "active" : ""}`} id="dot-2"></div>
      <div className={`dot ${props.active === "3" ? "active" : ""}`} id="dot-3"></div>
      <div className={`dot ${props.active === "4" ? "active" : ""} `} id="dot-4"></div>
    </div>
  </div>;
}
