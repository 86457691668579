import React from "react";
import { useHistory } from "react-router-dom";
import "./content-component.scss";

export default function ContentComponent(props) {
  const history = useHistory();
  return (
    <section className="content-section">
      <div className="container">
        <div className="row mb-4">
          <div className="col-12 col-md-6">
            <img
              src="/assets/images/promotion-bg-desktop.png"
              alt="Refer & earn with Syiok! Loyalty Programme"
              width="100%"
              className="d-none d-md-block mb-3"
            />
            <img
              src="/assets/images/promotion-bg.png"
              alt="Refer & earn with Syiok! Loyalty Programme"
              width="100%"
              className="d-md-none mb-3"
            />
          </div>
          <div className="col-12 col-md-6">
            <div>
              <h2 style={{fontSize: "1.625rem"}}>
                <b>
                  On top of your free engine oil, you can also get other
                  freebies from us!
                </b>
              </h2>
              <p>
                All you have to do is get your friends to service their vehicle{" "}
                with Quartz engine oil at our Quartz Auto Services (QAS).
              </p>
              <p>
                Upon the successful completion of their first service, you will be{" "}
                rewarded with limited edition merchandise!
              </p>
              <p>How to earn, you ask? Click the button below!</p>
              <p>
                <a
                  href={process.env.PUBLIC_URL + "/assets/pdf/Quartz_Syiok!_Loyalty_Programme_MGM_T&C_02122021.pdf"}
                  target="_blank"
                  className="text-primary"
                >
                  Terms &amp; conditions apply.
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 mb-4">
            <button
              className="tom-button primary-button"
              onClick={() => history.push("/refer-and-earn")}
            >
              Refer A Friend
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
