import axios from "axios";
import React, { useEffect } from "react";
import { apiURL } from "../../../config/config";

// import { Link, useHistory } from "react-router-dom";
// import TopSectionScreen from "../top-section/top-section";
import "./subscribe.scss";

export default function SubscribeScreen(props) {
  useEffect(() => {
    unsubscribeEmail();
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  const unsubscribeEmail = async () => {
    const email = urlParams.get("email");

    try {
      const response = await axios.get(
        `${apiURL}/api/subscription/mail?email=${email}&is_enabled=false`,
      );
    } catch (error) {
      console.log({ error: error })
    }
  };

  return (
    <section className="subscribe-screen">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <span className="text-primary syiok-logo">Quartz Syiok!</span>
            <h2>
              <b>You have been successfully unsubscribed.</b>
            </h2>
          </div>
        </div>
      </div>
    </section>
  );
}
