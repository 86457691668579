import Axios from "axios";
import querystring from "querystring";
import { apiURL } from "../../../../../config/config";

export const verifiedOTPAPI = async (mobile_number, tac) => {
    try {
        const response = await Axios.post(
            `${apiURL}/api/user/verification`,
            querystring.stringify({
                mobile_number,
                tac
            })
        );

        return response.data;
    } catch (error) {
        if (error?.message == 'Network Error') {
          return {
            status_code: 'internet'
          }
        } else {
          return {
            status_code: 500
          }
        }
    }
}