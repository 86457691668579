import React from "react";
import "./footer.scss";

export default function FooterComponent(props) {
  let year = new Date().getFullYear();
  const openSocialUrl = (url) => {
    window.open(url, "_blank");
  };
  return (
    <section className="home-footer-component">
      <div className="row footer-content">
        <div className="col-12 py-1 px-5">
          <div className="text-center">
            <span onClick={() => openSocialUrl("https://www.totalenergies.my/")}>
              <i className="icon-www pr-1"></i>
            </span>
            <span
              onClick={() => openSocialUrl(
                "https://www.facebook.com/TotalEnergiesMalaysia/ "
              )}
            >
              <i className="icon-facebook-circular-logo pl-1"></i>
            </span>
          </div>
          <hr />
          <p className="text-center mb-0">
            <a href={'/assets/pdf/Quartz_Syiok!_Loyalty_Programme_Privacy Policy_26112021.pdf'} target="_blank">Cookies &amp; Privacy</a> &nbsp;&nbsp;&nbsp; |&nbsp;&nbsp;&nbsp; <a href="https://www.totalenergies.my/legal-notices" target="_blank">Legal</a>
            &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp; ©TotalEnergies - {year}
          </p>
        </div>
      </div>
    </section>
  );
}
